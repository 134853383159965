import React from 'react'
import { Spinner, Stepper } from 'src/components/ui'
import {
  GqlModelMethodFragment,
  GqlModelTypeFragment,
  TagDto,
} from 'src/services'
import { ModelTypes } from 'src/types'
import { useTitle } from 'src/utility'
import { ErrorDisplay } from 'pages/app'
import { useTags } from 'pages/site/tags/api'
import { TimeRangeProvider } from 'src/contexts/timeRange'
import { useModelTypes } from '../api'
import { ForecastDefaultMethod } from '../model/model.utils'
import { steps, Record } from './steps'
import { useModelCreation } from './useModelCreation'
import { CreateModelNavigationButtons } from './components'

function modelTypenameToModelTypes(modelTypename?: ModelTypes | null): string {
  if (modelTypename === 'AnomalyModel') return 'Anomaly Detection'
  if (modelTypename === 'ForecastModel') return 'Forecast'
  if (modelTypename === 'PrescriptiveModel') return 'Prescriptive'
  return ''
}

function getModelMethod(
  model: Record,
  modelType: GqlModelTypeFragment,
): GqlModelMethodFragment | undefined {
  return modelType.methods.find(({ name }) =>
    model.modelType === 'ForecastModel'
      ? name === ForecastDefaultMethod.FORECAST_WITH_HORIZON
      : model.modelType === 'PrescriptiveModel'
      ? name === 'Prescriptive'
      : name === 'Nowcast',
  )
}

const initModel: Record = {
  modelType: null,
  outputTag: null,
  inputTags: [],
  name: '',
}

export function CreateModelPage(): JSX.Element {
  useTitle('Create model')
  const modelTypesQuery = useModelTypes()
  const tagsQuery = useTags()

  if (modelTypesQuery.isLoading || tagsQuery.isLoading) {
    return <Spinner />
  }

  if (modelTypesQuery.isError) {
    return (
      <ErrorDisplay
        error={modelTypesQuery.error}
        message="Failed to load model types"
      />
    )
  }

  if (tagsQuery.isError) {
    return (
      <ErrorDisplay error={tagsQuery.error} message="Failed to load tags" />
    )
  }

  return (
    <TimeRangeProvider>
      <CreateModelInner
        modelTypes={modelTypesQuery.data}
        tags={tagsQuery.data}
      />
    </TimeRangeProvider>
  )
}

type CreateModelInnerProps = {
  modelTypes: GqlModelTypeFragment[]
  tags: TagDto[]
}

const CreateModelInner = React.memo(function CreateModelInner({
  modelTypes,
  tags,
}: CreateModelInnerProps): JSX.Element {
  const [isAddInputTagsModalOpen, setIsAddInputTagsModalOpen] =
    React.useState(false)
  const [step, setStep] = React.useState(0)
  const [model, setModel] = React.useState(initModel)
  const { createModel, isLoading } = useModelCreation()

  const Step = steps[step].render

  const handleNextStep = (nextStep: number): void => {
    if (nextStep > step) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      steps[nextStep - 1].completed(model) && setStep(nextStep)
      if (
        model.modelType === 'PrescriptiveModel' &&
        nextStep === 2 &&
        !model.inputTags?.length
      ) {
        setIsAddInputTagsModalOpen(true)
      }
    } else {
      setStep(nextStep)
    }
  }

  const handleUpdateModel = (updates: Record): void => {
    if (updates && updates.modelType) {
      setStep(1)
    }
    setModel(model => ({ ...model, ...updates }))
  }

  async function handleCreateModel(): Promise<void> {
    const modelType = modelTypes.find(
      ({ name }) => modelTypenameToModelTypes(model.modelType) === name,
    )
    if (!modelType) {
      console.error(`Could not find model type: ${model.modelType}`)
      return
    }

    const method = getModelMethod(model, modelType)
    if (!method) {
      console.error(`Could not find model method: ${model.modelType}`)
      return
    }

    const tag = tags.find(tag => tag.tagName === model.outputTag)
    if (!tag || !tags) {
      console.error(`Could not find output tag: ${model.outputTag}`)
      return
    }

    await createModel({ model, modelType, method, tags, tag })
  }

  return (
    <div
      className="mx-auto my-0 p-[1em]"
      style={{ maxWidth: steps[step].maxWidth ?? 1200 }}
    >
      <div className="flex w-full justify-center">
        <Stepper
          steps={steps.map((s, i) => ({
            label: s.label,
            state:
              step === i
                ? 'active'
                : s.completed(model)
                ? 'completed'
                : 'default',
          }))}
          onClick={handleNextStep}
        />
      </div>
      <Step
        model={model}
        updateModel={handleUpdateModel}
        isAddInputTagsModalOpen={isAddInputTagsModalOpen}
        setIsAddInputTagsModalOpen={setIsAddInputTagsModalOpen}
      />
      <CreateModelNavigationButtons
        step={step}
        model={model}
        isLoading={isLoading}
        setStep={setStep}
        handleNextStep={handleNextStep}
        handleCreateModel={handleCreateModel}
        setIsAddInputTagsModalOpen={setIsAddInputTagsModalOpen}
      />
    </div>
  )
})
