import { Text } from 'src/components/ui'
import { TableConfig } from '../table.types'

export const getOpcConnectionsTableConfig = (): TableConfig => {
  return {
    allowSorting: true,
    columns: [
      {
        field: 'name',
        headerName: 'Connection Name',
        initialFlex: 300,
        cellRenderer: ({ data }: any) => {
          return <Text bold>{data.name || data.id}</Text>
        },
      },
      {
        field: 'endpoint',
        headerName: 'Endpoint',
        initialFlex: 300,
        cellRenderer: ({ data }: any) => {
          return <Text>{data.endpoint}</Text>
        },
      },
    ],
  }
}
