import { Switch as SwitchShadcn } from 'src/components/shadcn/ui/switch'
import { Text } from 'src/components/ui'

type Props = {
  value: boolean
  onChange: (value: boolean) => void
  label?: string
  labelPosition?: 'left' | 'right'
  bold?: boolean
  disabled?: boolean
}

export function Switch({
  value,
  onChange,
  label,
  labelPosition = 'right',
  bold,
  disabled,
}: Props): JSX.Element {
  return (
    <div
      data-testId="switch-conntainer"
      className="flex items-center space-x-xs"
    >
      {label && labelPosition === 'left' && (
        <Text testId="switch-left-label" bold={bold}>
          {label}
        </Text>
      )}
      <SwitchShadcn
        checked={value}
        onCheckedChange={onChange}
        disabled={disabled}
      />
      {label && labelPosition === 'right' && (
        <Text testId="switch-right-label" bold={bold}>
          {label}
        </Text>
      )}
    </div>
  )
}
