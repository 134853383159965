import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Input, TextInputProps } from 'src/components/ui'

type Props = Omit<
  TextInputProps,
  'iconLeft' | 'iconRight' | 'type' | 'onIconRightClick' | 'placeholder'
>

export function SearchInput({ ...props }: Props): JSX.Element {
  function handleIconRightClick(): void {
    props.setValue('')
  }

  return (
    <Input
      {...props}
      type="text"
      placeholder="Search..."
      iconLeft={regular('search')}
      iconLeftProps={{ className: 'text-text-tertiary', size: 'regular' }}
      iconRight={props.value?.length > 0 ? light('xmark') : undefined}
      iconRightProps={{
        className: 'text-text-tertiary cursor-pointer',
        size: 'regular',
      }}
      onIconRightClick={handleIconRightClick}
    />
  )
}
