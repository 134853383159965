import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Icon, Text, Modal } from 'src/components/ui'

type Props = {
  isLoading: boolean
  viewName: string
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
}

export function DeleteViewModal({
  isLoading,
  isOpen,
  onClose,
  viewName,
  onConfirm,
}: Props): JSX.Element {
  return (
    <Modal
      title={`Delete ${viewName}`}
      isOpen={isOpen}
      close={onClose}
      footer={
        <Modal.Footer
          onCancel={onClose}
          onConfirm={onConfirm}
          cancelLabel="Cancel"
          confirmLabel="Delete View"
          type="danger"
          buttonDisabled={isLoading}
        />
      }
    >
      <div className="flex max-w-[445px] flex-col items-center">
        <Icon icon={light('triangle-exclamation')} className="my-l size-xl" />
        <Text
          variant="content-rubik"
          bold
          className="my-xs text-center text-[16px]"
        >
          Are you sure you want to{' '}
          <span className="font-500 text-text-danger">delete {viewName}</span>?
        </Text>
        <Text variant="content" className="my-s">
          Deleting this view is ireversible and all settings applied to this
          view will be lost.
        </Text>
      </div>
    </Modal>
  )
}
