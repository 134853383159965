import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Link } from 'react-router-dom'
import { compare } from 'semver'
import { Text, TableConfig, Icon, Tooltip } from 'src/components/ui'
import { GatewayRelease } from 'src/services'
import { humanDate } from 'src/utility/time'

export const downloadsTableConfig = (): TableConfig<GatewayRelease> => {
  return {
    allowSearch: false,
    allowSorting: true,
    fixedColumnMenu: false,
    columns: [
      {
        field: 'semver',
        headerName: 'Version',
        initialFlex: 80,
        suppressHeaderMenuButton: true,
        comparator: (a, b) => compare(a, b),
        cellRenderer: ({ data }: any) => (
          <Link
            to={`/downloads/gateway-release/${data.semver}`}
            className="text-text-brand no-underline"
          >
            <Text bold>{data.semver}</Text>
          </Link>
        ),
      },
      {
        field: 'summary',
        headerName: 'Summary',
        initialFlex: 300,
        sortable: false,
        suppressHeaderMenuButton: true,
        cellRenderer: ({ value }: any) => (
          <Text variant="content">{value}</Text>
        ),
      },
      {
        field: 'tags',
        headerName: 'Release Highlights',
        initialFlex: 100,
        suppressHeaderMenuButton: true,
        sortable: false,
        valueFormatter: ({ value }: any) => value.join(', '),
        cellRenderer: ({ value }: any) => (
          <div className="flex items-center gap-xs">
            {value.map((t: any) => (
              <Text key={t}>{t}</Text>
            ))}
          </div>
        ),
      },
      {
        field: 'release',
        headerName: 'Release Date',
        initialFlex: 100,
        sortable: false,
        suppressHeaderMenuButton: true,
        cellRenderer: ({ value }: any) => (
          <Text variant="content">{humanDate(value)}</Text>
        ),
      },
      {
        field: 'url',
        colId: 'actions',
        headerName: '',
        width: 80,
        sortable: false,
        suppressHeaderMenuButton: true,
        cellRenderer: ({ value }: any) => {
          return (
            <Tooltip
              containerClassName="flex items-center"
              render={() => <Text>Download installer</Text>}
            >
              <Link
                to={value}
                target="_blank"
                className="flex items-center text-inherit"
              >
                <Icon
                  className="cursor-pointer rounded-2xs p-xs transition-all hover:bg-background-hover"
                  icon={light('download')}
                />
              </Link>
            </Tooltip>
          )
        },
      },
    ],
  }
}
