import { useMemo } from 'react'
import {
  FetchPrescriptiveModel,
  GqlModelTypeFragment,
  ModelInputTag,
} from 'src/services'
import { NEW, RUNNING } from 'models/model/model.utils'
import { Spinner } from 'src/components/ui'
import { useLatestRecomendation } from 'pages/site/models/api'
import { ErrorDisplay } from 'pages/app'
import { ModelActions, ModelOverview, NewModelInfo } from '../../components'
import {
  Forecast,
  OtherFactors,
  RecomendedImprovements,
  TagCharts,
} from './sections'

type Props = {
  model: FetchPrescriptiveModel
  modelTypes: GqlModelTypeFragment[]
  inputTags: ModelInputTag[]
}

export function PrescriptiveModel({
  model,
  modelTypes,
  inputTags,
}: Props): JSX.Element {
  const controllableInputs = useMemo(
    () => inputTags.filter(t => t.isControllable),
    [inputTags],
  )

  const uncontrollableInputs = useMemo(
    () => inputTags.filter(t => !t.isControllable),
    [inputTags],
  )

  const latestRecomendationQuery = useLatestRecomendation(
    model.id,
    model.state === RUNNING,
  )

  if (model.state === RUNNING && latestRecomendationQuery.isLoading) {
    return <Spinner />
  }

  if (latestRecomendationQuery.isError) {
    return (
      <ErrorDisplay
        error={latestRecomendationQuery.error}
        action={() => latestRecomendationQuery.refetch}
      />
    )
  }

  return (
    <div className="flex flex-col gap-m">
      {model.state === NEW && <NewModelInfo />}
      <div className="flex flex-col gap-[1em] medium:flex-row">
        <ModelOverview
          modelTypes={modelTypes}
          model={model}
          className="flex flex-1 flex-col"
        />
        <ModelActions
          model={model}
          className="medium:min-w-[calc(300px-1em)]"
        />
      </div>
      <Forecast model={model} />
      <RecomendedImprovements
        model={model}
        controllableInputTags={controllableInputs}
        latestRecomendations={latestRecomendationQuery.data}
      />
      <TagCharts
        modelId={model.id}
        controllableInputTags={controllableInputs}
        latestRecomendations={latestRecomendationQuery.data}
      />
      <OtherFactors
        model={model}
        uncontrollableInputTags={uncontrollableInputs}
      />
    </div>
  )
}
