import classNames from 'classnames'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { ComponentPropsWithoutRef, useCallback, useRef, useState } from 'react'
import { Input as InputComponent } from '@components/input'
import { Icon } from 'src/components/ui'
import { useClickOutside } from 'src/utility'

type InputSize = 'small' | 'regular'

export type BaseProps = {
  placeholder?: string
  disabled?: boolean
  autoFocus?: boolean
  isError?: boolean
  size?: InputSize

  // Icon props
  iconLeft?: IconProp
  iconLeftProps?: any
  onIconLeftClick?: () => void
  iconRight?: IconProp
  iconRightProps?: any
  onIconRightClick?: () => void

  // Styling props
  containerClassName?: string
  inputClassName?: string

  // Event handlers
  onFocus?: () => void
  onBlur?: () => void
  onClickOutside?: () => void

  // Input props
  inputProps?: ComponentPropsWithoutRef<'input'>
}

export type TextInputProps = BaseProps & {
  type: 'text'
  value: string
  setValue: (value: string) => void
}

export type NumberInputProps = BaseProps & {
  type: 'number'
  value: number
  setValue: (value: number) => void
}

const sizeClasses: Record<InputSize, string> = {
  small: 'h-[30px]',
  regular: 'h-[40px]',
}

export function Input({
  type,
  value,
  setValue,
  placeholder,
  disabled = false,
  autoFocus = false,
  isError = false,
  size = 'regular',
  // Icon props
  iconLeft,
  iconLeftProps,
  onIconLeftClick,
  iconRight,
  iconRightProps,
  onIconRightClick,
  // Styling props
  containerClassName,
  inputClassName,
  // Event handlers
  onFocus,
  onBlur,
  onClickOutside,

  // Input props
  inputProps,
}: TextInputProps | NumberInputProps): JSX.Element {
  const [inFocus, setInFocus] = useState<boolean>(false)
  const inputRef = useRef<HTMLInputElement>(null)

  useClickOutside(inputRef, () => onClickOutside?.())

  function handleFocus(): void {
    setInFocus(true)
    onFocus?.()
  }

  function handleBlur(): void {
    setInFocus(false)
    onBlur?.()
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
    if (type === 'number') {
      const value = e.target.valueAsNumber
      if (!isNaN(value)) {
        setValue(value)
      }
    } else {
      setValue(e.target.value)
    }
  }

  const handleIconLeftClick = useCallback(() => {
    onIconLeftClick?.()
  }, [onIconLeftClick])

  const handleIconRightClick = useCallback(() => {
    onIconRightClick?.()
  }, [onIconRightClick])

  return (
    <div
      data-testid="input-container"
      className={classNames(
        'flex items-center gap-xs',
        'bg-background px-xs rounded-2xs',
        'border border-solid',
        'text-text text-description',
        isError
          ? 'border-border-danger'
          : 'border-border focus-within:border-border-brand',
        disabled
          ? 'opacity-60'
          : 'active:border-border-secondary transition duration-150',
        !inFocus && !disabled && 'hover:bg-background-hover',
        sizeClasses[size],
        containerClassName,
      )}
    >
      {!!iconLeft && (
        <Icon
          data-testid="input-icon-left"
          icon={iconLeft}
          onClick={handleIconLeftClick}
          className={classNames('size-s text-icon', iconLeftProps?.className)}
          {...iconLeftProps}
        />
      )}
      <InputComponent
        data-testid="input-field"
        ref={inputRef}
        type={type}
        value={value}
        onChange={handleChange}
        className={classNames('h-full', inputClassName)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        disabled={disabled}
        placeholder={placeholder}
        autoFocus={autoFocus}
        {...inputProps}
      />
      {!!iconRight && (
        <Icon
          data-testid="input-icon-right"
          icon={iconRight}
          onClick={handleIconRightClick}
          className={classNames('size-s text-icon', iconRightProps?.className)}
          {...iconRightProps}
        />
      )}
    </div>
  )
}
