import classNames from 'classnames'
import { GqlLatestRecomendationFragment, ModelInputTag } from 'src/services'
import { TagChart } from '../TagChart'

type Props = {
  modelId: string
  inputTags: ModelInputTag[]
  latestRecomendations?: GqlLatestRecomendationFragment
}
export function GridLayout({
  modelId,
  inputTags,
  latestRecomendations,
}: Props): JSX.Element {
  return (
    <div className="w-full py-s @container">
      <div
        className={classNames(
          'grid gap-m',
          'grid-cols-1 @2xl:grid-cols-2 @6xl:grid-cols-3',
        )}
      >
        {inputTags.map(tag => {
          const recommendedValue =
            latestRecomendations?.latest?.recommendations.find(
              r => r.tagId === tag.node.id,
            )?.value
          return (
            <div key={tag.node.tagName} className="aspect-[2.2] flex-none">
              <TagChart
                modelId={modelId}
                tag={tag.node}
                recommendedValue={recommendedValue}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}
