import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { hasMatch, score } from 'fzy.js'
import { debounce } from 'lodash'
import { Button, Text, Tooltip, Checkbox, SearchInput } from 'src/components/ui'
import { useAuth } from 'src/contexts/auth'
import { GqlFactoryTrendViewFragment } from 'src/services'
import { zIndex } from 'src/utility/constants/StyleConstants'

type Props = {
  views: GqlFactoryTrendViewFragment[]
  onClose?: () => void
  viewId?: string
}
export function ViewsContent({ views, onClose, viewId }: Props): JSX.Element {
  const { viewer } = useAuth()
  const [search, setSearch] = useState('')
  const [showMine, setShowMine] = useState(false)
  const [displayedViews, setDisplayedViews] = useState(views)

  const navigate = useNavigate()

  useEffect(() => {
    setDisplayedViews(views)
  }, [views])

  const doesFilterPass = useCallback(
    (view: GqlFactoryTrendViewFragment): boolean => {
      if (search === '') return true
      return hasMatch(search.replaceAll(' ', ''), view.name.toLowerCase())
    },
    [search],
  )

  useEffect(() => {
    // debounced search trigger displayed views update
    debounce(() => {
      setDisplayedViews(
        views
          .filter(view => doesFilterPass(view))
          .filter(view => (showMine ? view.user.id === viewer.id : true))
          .sort((a, b) => {
            const searchStripped = search.replaceAll(' ', '').toLowerCase()
            if (searchStripped.length) {
              return score(
                searchStripped,
                a.name.toLowerCase().replaceAll(' ', ''),
              ) >
                score(searchStripped, b.name.toLowerCase().replaceAll(' ', ''))
                ? -1
                : 1
            }
            return 0
          }),
      )
    }, 300)()
  }, [doesFilterPass, search, showMine, viewId, viewer.id, views])

  return (
    <div className="flex w-full max-w-full flex-col">
      <div className="flex flex-col gap-2xs">
        <Text variant="small" bold>
          Select or Search for a View:
        </Text>
        <SearchInput size="small" value={search} setValue={setSearch} />
      </div>
      <div className="my-s flex max-h-[280px] flex-col overflow-y-auto">
        {displayedViews.map(view => (
          <Tooltip
            key={view.id}
            render={() => <Text>You are currently looking at this view</Text>}
            isOpen={viewId === view.id ? undefined : false}
            direction="bottom"
            zIndex={zIndex.header + 1}
          >
            <div
              className="mt-xs flex h-[26px] cursor-pointer items-center justify-between border-0 border-b border-solid border-border"
              onClick={() => {
                navigate(
                  `${window.location.pathname.split('/trend')[0]}/trend/${
                    view.id
                  }`,
                )
                if (onClose) onClose()
              }}
            >
              <Text
                variant="content"
                bold
                className={classNames(
                  'truncate max-w-[200px]',
                  view.id === viewId && 'text-text-disabled',
                )}
              >
                {view.name}
              </Text>
              <Text className="max-w-[100px] truncate text-text-tertiary">
                {view.user.name}
              </Text>
            </div>
          </Tooltip>
        ))}
      </div>
      <div className="flex justify-between">
        <div
          className="flex cursor-pointer items-center p-xs"
          onClick={() => setShowMine(!showMine)}
        >
          <Checkbox value={showMine} onChange={() => null} />
          <label className="ml-2xs cursor-pointer">
            <Text className="inline-block">Show Only Mine</Text>
          </label>
        </div>
        <Button
          title={'Manage Saved Views'}
          variant="tertiary"
          onClick={() => {
            navigate(
              `${window.location.pathname.split('/trend')[0]}/trend/views`,
            )
          }}
        />
      </div>
    </div>
  )
}
