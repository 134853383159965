import classNames from 'classnames'
import React, { useRef, useState } from 'react'
import { GqlLatestRecomendationFragment, ModelInputTag } from 'src/services'
import { TagChart } from '../TagChart'

type Props = {
  modelId: string
  inputTags: ModelInputTag[]
  latestRecomendations?: GqlLatestRecomendationFragment
}

export function RowLayout({
  modelId,
  inputTags,
  latestRecomendations,
}: Props): JSX.Element {
  const containerRef = useRef<HTMLDivElement>(null)
  const [isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0)

  const handleMouseDown = (e: React.MouseEvent): void => {
    setIsDragging(true)
    setStartX(e.pageX - (containerRef.current?.offsetLeft || 0))
    setScrollLeft(containerRef.current?.scrollLeft || 0)
  }

  const handleMouseMove = (e: React.MouseEvent): void => {
    if (!isDragging || !containerRef.current) return
    const x = e.pageX - (containerRef.current.offsetLeft || 0)
    const walk = (x - startX) * 1.5
    containerRef.current.scrollLeft = scrollLeft - walk
  }

  const handleMouseUp = (): void => setIsDragging(false)
  const handleMouseLeave = (): void => setIsDragging(false)

  return (
    <div
      ref={containerRef}
      className="no-scrollbar w-full overflow-x-auto py-s"
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseLeave}
    >
      <div className="no-scrollbar w-full cursor-grab whitespace-nowrap active:cursor-grabbing">
        <div className={classNames('flex items-center gap-xs')}>
          {inputTags.map(tag => {
            const recommendedValue =
              latestRecomendations?.latest?.recommendations.find(
                r => r.tagId === tag.node.id,
              )?.value
            return (
              <div
                key={tag.node.tagName}
                className="h-[177px] w-[400px] flex-none"
              >
                <TagChart
                  modelId={modelId}
                  tag={tag.node}
                  recommendedValue={recommendedValue}
                />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
