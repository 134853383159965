import { AgGridReact } from 'ag-grid-react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  ColDef,
  IRowNode,
  ITooltipParams,
  RowSelectionOptions,
  SelectionChangedEvent,
} from 'ag-grid-community'
import classNames from 'classnames'
import toast from 'react-hot-toast'
import { useFlags } from 'launchdarkly-react-client-sdk'
import {
  Button,
  SearchInput,
  ProgressBar,
  tableTheme,
  Text,
} from 'src/components/ui'
import { GqlOpcUaNode, OpcUaSyncTasks } from 'src/services'
import { ErrorDisplay } from 'pages/app'
import { useUpdateSubscriptionMutation } from '../opc-ua-connection.api'
import {
  CustomTooltip,
  getHierarchyTreeConfig,
  SaveSubscriptionButton,
} from './opc-ua-hierarchy-tree'
import {
  getMainColumnDef,
  useChangeIndicators,
  useDebouncedFilterChange,
  useFuzzyFilter,
  useHandleSelected,
  useLoadOpcNodes,
  useNodeMap,
  useNodePaths,
  useNodeSubscriptions,
  useRowClassNames,
} from './opc-ua-hierarchy.utils'

type Props = {
  gatewayId: string
  connectionId: string
  siteId: string
  tasks: OpcUaSyncTasks
}

export enum Change {
  ADD = 'add',
  REMOVE = 'remove',
}

export function OpcUaHierarchyTree({
  connectionId,
  gatewayId,
  siteId,
  tasks,
}: Props): JSX.Element {
  const numberOfNodesToFetch = useMemo(
    () => tasks.lastSuccessfulHierarchySyncTask?.nodesSeen ?? 1,
    [tasks],
  )
  const { isLoading, isError, error, nodes, nodesLoaded, refetch } =
    useLoadOpcNodes(connectionId)

  if (isLoading) {
    return (
      <div className="flex h-[150px] items-center justify-center rounded-2xs border border-solid border-border">
        <div className="flex w-full max-w-[250px] flex-col gap-xs text-center">
          <Text className="text-text-tertiary">
            {`Nodes loaded ${nodesLoaded}/${numberOfNodesToFetch}... (${Math.min(
              Math.round((nodesLoaded / numberOfNodesToFetch) * 100),
              100,
            )}%)`}
          </Text>
          <ProgressBar
            value={((nodesLoaded ?? 0) / numberOfNodesToFetch) * 100}
          />
        </div>
      </div>
    )
  }

  if (isError) {
    return (
      <ErrorDisplay
        error={error}
        message="Failed to fetch hierarchy nodes!"
        action={refetch}
      />
    )
  }

  if (nodes) {
    return (
      <HierarchyTree
        treeData={nodes}
        siteId={siteId}
        gatewayId={gatewayId}
        connectionId={connectionId}
      />
    )
  }

  return <></>
}

type TreeProps = Omit<Props, 'tasks'> & {
  treeData: GqlOpcUaNode[]
}

function HierarchyTree({
  treeData,
  siteId,
  gatewayId,
  connectionId,
}: TreeProps): JSX.Element {
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false)
  const { opcUaDataSubscriptions } = useFlags()
  const gridRef = useRef<AgGridReact>(null)

  const [isComputing, setIsComputing] = useState<boolean>(false)

  const [searchInput, setSearchInput] = useState<string>('')
  const searchInputRef = useRef<string>(searchInput)
  const subscriptionMutation = useUpdateSubscriptionMutation(
    gatewayId,
    siteId,
    connectionId,
  )

  // Keeping track of the changes to the subscription
  const [subscriptionChanges, setSubscriptionChanges] =
    useState<Map<string, Change>>()
  const subscriptionChangesRef = useRef<Map<string, Change>>(new Map())

  // Keeping track of parents that have changed
  const [parentsChanged, setParentsChanged] = useState<Map<string, number>>()
  const parentsChangedRef = useRef<Map<string, number>>(new Map())

  // Custom hooks
  const handleRowClassNames = useRowClassNames(
    opcUaDataSubscriptions,
    subscriptionChangesRef,
    parentsChangedRef,
  )
  const handleSelected = useHandleSelected(opcUaDataSubscriptions, gridRef)
  const getChangeIndicators = useChangeIndicators(
    subscriptionChangesRef,
    parentsChangedRef,
  )

  // Custom hooks for search
  const { doesFuzzyFilterPass, isFuzzyFilterPresent } =
    useFuzzyFilter(searchInputRef)
  const debouncedFilterChange = useDebouncedFilterChange(
    gridRef,
    searchInputRef,
  )

  useEffect(() => {
    debouncedFilterChange()
  }, [
    isFuzzyFilterPresent,
    doesFuzzyFilterPass,
    searchInput,
    debouncedFilterChange,
  ])

  // Convert the tree data to a map for easier access
  const nodeMap = useNodeMap(treeData)

  // Generate the tree structure
  const rootNode = treeData.find(node => node.extendedNodeId === 'i=85')
  const [convertedNodes, setConvertedNodes] = useState(
    useNodePaths(nodeMap, rootNode?.id ?? ''),
  )

  const convertedNodesArray = useMemo(
    () => [...convertedNodes.values()],
    [convertedNodes],
  )

  const { subscribedNodes, errorNodes } =
    useNodeSubscriptions(convertedNodesArray)

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      minWidth: 300,
      initialFlex: 1,
      sortable: false,
      resizable: true,
    }
  }, [])

  const autoGroupColumnDef = useMemo<ColDef>(
    () => getMainColumnDef(errorNodes),
    [errorNodes],
  )

  // Update the refs when the state changes
  useEffect(() => {
    if (subscriptionChanges) {
      subscriptionChangesRef.current = subscriptionChanges
    }
    if (parentsChanged) {
      parentsChangedRef.current = parentsChanged
    }
    searchInputRef.current = searchInput
  }, [subscriptionChanges, parentsChanged, searchInput])

  // Search

  const handleSelectionChange = useCallback(
    (params: SelectionChangedEvent) => {
      if (params.source === 'gridInitializing') return
      setIsComputing(true)
      const selectedNodes = params.api.getSelectedRows()
      // Move the compute off the main thread
      const worker = new Worker(
        new URL('./selectionWorker.ts', import.meta.url),
      )
      worker.postMessage({
        subscriptionChanges: subscriptionChangesRef.current,
        parentChanges: parentsChangedRef.current,
        subscribedNodes: subscribedNodes,
        selectedNodes: selectedNodes,
        convertedNodes: convertedNodes,
      })

      worker.onmessage = e => {
        const { updatedSubscriptionChanges, updatedParentChanges } = e.data
        setSubscriptionChanges(updatedSubscriptionChanges)
        setParentsChanged(updatedParentChanges)
        setIsComputing(false)

        // Redraw the rows to show new styles for the new changes
        params.api.redrawRows()
      }
    },
    [convertedNodes, subscribedNodes],
  )

  const handleSelectableRow = useCallback((node: IRowNode): boolean => {
    return !!node.data?.isSubscribable
  }, [])

  // Count add changes
  const nodesAdded = useMemo(() => {
    return Array.from(subscriptionChanges?.values() ?? []).filter(
      change => change === Change.ADD,
    ).length
  }, [subscriptionChanges])

  // Count remove changes
  const nodesRemoved = useMemo(() => {
    return Array.from(subscriptionChanges?.values() ?? []).filter(
      change => change === Change.REMOVE,
    ).length
  }, [subscriptionChanges])

  const handleConfirmChanges = useCallback(async () => {
    if (!opcUaDataSubscriptions) return
    setIsConfirmLoading(true)
    const nodesToAdd = Array.from(subscriptionChangesRef.current.entries())
      .filter(([_, change]) => change === Change.ADD)
      .map(([id]) => id)
    const nodesToRemove = Array.from(subscriptionChangesRef.current.entries())
      .filter(([_, change]) => change === Change.REMOVE)
      .map(([id]) => id)

    const newSubscribedNodes = new Set(subscribedNodes.keys())
    nodesToAdd.forEach(id => newSubscribedNodes.add(id))
    nodesToRemove.forEach(id => newSubscribedNodes.delete(id))
    await subscriptionMutation.mutateAsync(
      {
        toSubscribe: nodesToAdd,
        toUnsubscribe: nodesToRemove,
      },
      {
        onSuccess: data => {
          // Update nodes that were subscribed and unsubscribed to avoid refetching all of the nodes
          const newMap = new Map(convertedNodes)
          data.updateNodeSubscription?.subscribed?.forEach(id => {
            const node = newMap.get(id)
            if (node) {
              node.isSubscribed = true
            }
          })

          data.updateNodeSubscription?.unsubscribed?.forEach(id => {
            const node = newMap.get(id)
            if (node) {
              node.isSubscribed = false
            }
          })
          setConvertedNodes(newMap)

          // reset changes
          setSubscriptionChanges(new Map())
          setParentsChanged(new Map())

          // close modal
          setIsConfirmLoading(false)
          toast.success('Subscription hierarchy successfully saved', {
            position: 'top-right',
            duration: 10000,
          })

          // Redraw the rows to show new styles for the new changes
          gridRef.current?.api.redrawRows()
        },
        onError: () => {
          setIsConfirmLoading(false)
        },
      },
    )
  }, [
    convertedNodes,
    opcUaDataSubscriptions,
    subscribedNodes,
    subscriptionMutation,
  ])

  const hnaldeDiscardStateChanges = useCallback(() => {
    setSubscriptionChanges(new Map())
    setParentsChanged(new Map())
    subscriptionChangesRef.current = new Map()
    parentsChangedRef.current = new Map()
  }, [setSubscriptionChanges, setParentsChanged])

  const handleDiscardChanges = useCallback(() => {
    if (!opcUaDataSubscriptions || !gridRef.current) return
    hnaldeDiscardStateChanges()
    const nodesToSelect: IRowNode[] = []
    const nodesToDeselect: IRowNode[] = []
    gridRef.current.api.forEachNode(node => {
      if (node.data.isSubscribed) {
        nodesToSelect.push(node)
      } else {
        nodesToDeselect.push(node)
      }
    })
    gridRef.current.api.setNodesSelected({
      nodes: nodesToDeselect,
      newValue: false,
      source: 'gridInitializing',
    })
    gridRef.current.api.setNodesSelected({
      nodes: nodesToSelect,
      newValue: true,
      source: 'gridInitializing',
    })
    gridRef.current.api.redrawRows()
  }, [hnaldeDiscardStateChanges, opcUaDataSubscriptions])

  const getRowSelectionConfig = useCallback((): RowSelectionOptions => {
    return {
      mode: 'multiRow',
      groupSelects: 'self',
      headerCheckbox: false,
      isRowSelectable: handleSelectableRow,
    }
  }, [handleSelectableRow])

  const memoedTree = useMemo(() => {
    return (
      <AgGridReact
        ref={gridRef}
        className="!h-[2000px]"
        theme={tableTheme.withParams({
          selectedRowBackgroundColor: 'transparent',
        })}
        enableCellTextSelection
        rowData={convertedNodesArray}
        treeData={true}
        groupDefaultExpanded={1}
        loading={isComputing}
        rowModelType="clientSide"
        suppressChangeDetection={true}
        rowSelection={
          opcUaDataSubscriptions ? getRowSelectionConfig() : undefined
        }
        onSelectionChanged={handleSelectionChange}
        onRowGroupOpened={event => {
          event.api.redrawRows({ rowNodes: [event.node] })
        }}
        getRowClass={handleRowClassNames}
        getDataPath={data => data.path}
        onFirstDataRendered={handleSelected}
        columnDefs={getHierarchyTreeConfig(
          opcUaDataSubscriptions,
          getChangeIndicators,
        )}
        getRowId={data => data.data.id}
        defaultColDef={defaultColDef}
        autoGroupColumnDef={autoGroupColumnDef}
        isExternalFilterPresent={isFuzzyFilterPresent}
        doesExternalFilterPass={doesFuzzyFilterPass}
        tooltipShowDelay={0}
        selectionColumnDef={{
          tooltipComponent: CustomTooltip,
          tooltipValueGetter: (p: ITooltipParams) =>
            !p.data.isSubscribable ? 'Unsupported data type' : null,
        }}
      />
    )
  }, [
    convertedNodesArray,
    isComputing,
    opcUaDataSubscriptions,
    getRowSelectionConfig,
    handleSelectionChange,
    handleRowClassNames,
    handleSelected,
    getChangeIndicators,
    defaultColDef,
    autoGroupColumnDef,
    isFuzzyFilterPresent,
    doesFuzzyFilterPass,
  ])

  return (
    <div className="flex flex-col gap-s">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-s">
          <Text>
            <span className="font-500">
              Subscribed: {subscribedNodes.size - nodesRemoved + nodesAdded}
            </span>{' '}
            (Added: {nodesAdded}, Removed: {nodesRemoved})
          </Text>
        </div>
        <div className="flex items-center gap-xs">
          <Button
            disabled={subscriptionChanges?.size === 0}
            variant="secondary"
            title="Discard Changes"
            onClick={handleDiscardChanges}
          />
          <SaveSubscriptionButton
            isError={subscriptionMutation.isError}
            onConfirm={handleConfirmChanges}
            isLoading={isConfirmLoading}
            nodesAdded={nodesAdded}
            nodesRemoved={nodesRemoved}
          />
        </div>
      </div>
      <div className="size-full min-h-[600px] max-w-full" id="HierarchyTree">
        <div className="flex size-full flex-col rounded-2xs bg-background">
          <div className="flex items-center justify-between gap-xs rounded-t-2xs border border-b-0 border-solid border-border bg-background-hover px-s py-2xs">
            <div>
              <Text variant="description" bold>
                OPC-UA Hierarchy
              </Text>
            </div>
            <SearchInput
              size="small"
              containerClassName="w-[200px]"
              value={searchInput}
              setValue={setSearchInput}
            />
          </div>
          <div
            className={classNames(
              'w-full',
              !isComputing && 'hierarchy-tree-idle',
            )}
          >
            {memoedTree}
          </div>
        </div>
      </div>
    </div>
  )
}
