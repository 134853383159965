import { CustomTooltipProps } from 'ag-grid-react'
import { Text } from 'src/components/ui'

export function CustomTooltip(props: CustomTooltipProps): JSX.Element {
  if (props.data.isSubscribable) {
    return <></>
  }
  return (
    <div className="rounded-xs bg-background-tertiary-active p-xs text-white">
      <Text bold>{props.value}</Text>
    </div>
  )
}
