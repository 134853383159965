import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { MutableRefObject, useCallback, useEffect, useMemo } from 'react'
import {
  ColDef,
  ICellRendererParams,
  IRowNode,
  RowClassParams,
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { hasMatch } from 'fzy.js'
import { debounce } from 'lodash'
import { GqlOpcUaNode, GqlOpcUaNodeClass } from 'src/services'
import { isDefined } from 'src/types'
import { useOpcHierarchyNodes } from '../opc-ua-connection.api'
import { GroupColumn } from './opc-ua-hierarchy-tree'
import { Change } from './OpcUaHierarchyTree'

export function getNodeIcon(
  nodeClass: GqlOpcUaNodeClass,
  type?: string,
): { icon: IconProp; className?: string } {
  if (nodeClass === GqlOpcUaNodeClass.Object && type === 'FolderType') {
    return { icon: light('folder'), className: 'text-icon-warning' }
  }
  if (nodeClass === GqlOpcUaNodeClass.Variable) {
    return { icon: light('sensor') }
  }

  return { icon: light('cube') }
}

/**
 * Hook to create paths for nodes in a node map.
 * @param nodeMap - The original map of nodes.
 * @returns A new map with paths added to each node.
 */
export function useNodePaths(
  nodeMap: Map<string, GqlOpcUaNode>,
  rootNodeId: string,
): Map<string, GqlOpcUaNode & { path?: string[] }> {
  return useMemo(() => {
    const processed = new Set<string>()
    const newNodeMap = new Map(
      Array.from(nodeMap.entries()).map(([key, value]) => [
        key,
        { ...value, path: [] as string[] },
      ]),
    )

    function addPaths(nodeId: string, path: string[] = []): void {
      if (processed.has(nodeId)) return
      processed.add(nodeId)

      const node = newNodeMap.get(nodeId)
      if (!node) return

      node.path = [...path, nodeId]

      for (const childNode of node.forwardReferences) {
        addPaths(childNode.targetId, node.path)
      }
    }

    addPaths(rootNodeId)
    return newNodeMap
  }, [nodeMap, rootNodeId])
}

type UseLoadOpcNodes = {
  isLoading: boolean
  isError: boolean
  error?: Error
  nodes: GqlOpcUaNode[]
  nodesLoaded: number
  refetch: () => void
}

/**
 * Hook to load OPC nodes for a connection.
 * @param connectionId - The ID of the connection to load nodes for.
 * @returns An object with the loading state and nodes.
 */
export function useLoadOpcNodes(connectionId: string): UseLoadOpcNodes {
  const connectionHierarchyQuery = useOpcHierarchyNodes(connectionId)

  // Fetch all pages of nodes
  useEffect(() => {
    if (
      connectionHierarchyQuery.hasNextPage &&
      !connectionHierarchyQuery.isFetching
    ) {
      connectionHierarchyQuery.fetchNextPage()
    }
  }, [connectionHierarchyQuery])

  const isLoading =
    (connectionHierarchyQuery.isLoading ||
      connectionHierarchyQuery.isFetching ||
      connectionHierarchyQuery.hasNextPage) ??
    false

  const nodesLoaded = useMemo(() => {
    return (
      connectionHierarchyQuery.data?.pages.reduce(
        (acc, page) => acc + (page.items?.filter(isDefined).length ?? 0),
        0,
      ) ?? 0
    )
  }, [connectionHierarchyQuery.data])

  return {
    isLoading,
    isError: connectionHierarchyQuery.isError,
    error: connectionHierarchyQuery.error ?? undefined,
    nodesLoaded,
    nodes: !isLoading
      ? connectionHierarchyQuery.data?.pages
          .flatMap(page => page.items)
          .filter(isDefined) ?? []
      : [],
    refetch: connectionHierarchyQuery.refetch,
  }
}

/**
 * Hook to get tree row class names.
 * @param opcUaDataSubscriptions
 * @param subscriptionChangesRef
 * @param parentsChangedRef
 * @returns A function to get row class names.
 */
export const useRowClassNames = (
  opcUaDataSubscriptions: boolean,
  subscriptionChangesRef: React.MutableRefObject<Map<string, Change>>,
  parentsChangedRef: React.MutableRefObject<Map<string, number>>,
): ((params: RowClassParams) => string) => {
  return useCallback(
    (params: RowClassParams): string => {
      if (!opcUaDataSubscriptions) return ''
      const { id } = params.data
      if (subscriptionChangesRef.current.has(id)) {
        const change = subscriptionChangesRef.current.get(id)
        if (change === Change.ADD) {
          return 'before:!hidden !bg-background-success before:!bg-background-success'
        }
        if (change === Change.REMOVE) {
          return 'before:!hidden !bg-background-danger-secondary before:!bg-background-danger-secondary'
        }
      }
      if (params.node.group && !params.node.expanded) {
        const value = parentsChangedRef.current.get(id)
        if (value && value > 0) {
          return '!bg-background-warning'
        }
      }
      return ''
    },
    [opcUaDataSubscriptions, subscriptionChangesRef, parentsChangedRef],
  )
}

/**
 * Hook to set initial subscribed nodes
 * @param opcUaDataSubscriptions
 * @param gridRef
 * @returns A function to handle selected nodes.
 */
export const useHandleSelected = (
  opcUaDataSubscriptions: boolean,
  gridRef: React.MutableRefObject<AgGridReact | null>,
): (() => void) => {
  return useCallback(() => {
    if (!opcUaDataSubscriptions || !gridRef.current) return
    const nodesToSelect: IRowNode[] = []
    gridRef.current.api.forEachNode(node => {
      if (node.data.isSubscribed) {
        nodesToSelect.push(node)
      }
    })
    gridRef.current.api.setNodesSelected({
      nodes: nodesToSelect,
      newValue: true,
    })
  }, [opcUaDataSubscriptions, gridRef])
}

/**
 * Hook to get changelog indicators.
 * @param opcUaDataSubscriptions
 * @param gridRef
 * @returns A function to get change indicators.
 */
export const useChangeIndicators = (
  subscriptionChangesRef: React.MutableRefObject<Map<string, Change>>,
  parentsChangedRef: React.MutableRefObject<Map<string, number>>,
): ((id: string) => string) => {
  return useCallback(
    (id: string): string => {
      if (subscriptionChangesRef.current.has(id)) {
        return subscriptionChangesRef.current.get(id) === Change.ADD ? '+' : '-'
      }
      const isChangedParent = !!parentsChangedRef.current?.get(id)
      if (isChangedParent) {
        return '+/-'
      }
      return ''
    },
    [subscriptionChangesRef, parentsChangedRef],
  )
}

type UseFuzzyFilter = {
  doesFuzzyFilterPass: (node: any) => boolean
  isFuzzyFilterPresent: () => boolean
}
/**
 * Hook to filter nodes based on search input.
 * @param searchInputRef
 * @returns An object with functions to filter nodes.
 */
export const useFuzzyFilter = (
  searchInputRef: MutableRefObject<string>,
): UseFuzzyFilter => {
  const doesFuzzyFilterPass = useCallback(
    (node: any): boolean => {
      const pattern = searchInputRef.current.replaceAll(' ', '')
      const nodeData = node.data as GqlOpcUaNode
      if (!pattern || !(nodeData.displayName ?? nodeData.browseName))
        return true

      const match =
        hasMatch(pattern, nodeData.displayName ?? nodeData.browseName) ||
        hasMatch(pattern, nodeData.nodeId)
      return match
    },
    [searchInputRef],
  )

  const isFuzzyFilterPresent = useCallback((): boolean => {
    return !!searchInputRef.current
  }, [searchInputRef])

  return { doesFuzzyFilterPass, isFuzzyFilterPresent }
}

/**
 * Hook to expand nodes that match the search input.
 * @param gridRef
 * @param searchInputRef
 * @returns A function to expand nodes.
 */
export const useExpandOnSearch = (
  gridRef: MutableRefObject<AgGridReact | null>,
  searchInputRef: MutableRefObject<string>,
): (() => void) => {
  return useCallback(() => {
    if (gridRef.current) {
      gridRef.current.api.forEachNodeAfterFilter(node => {
        const nodeName = node.data.displayName ?? node.data.browseName
        const isMatch =
          hasMatch(searchInputRef.current, nodeName) ||
          hasMatch(searchInputRef.current, node.data.nodeId)
        if (!isMatch) {
          node.setExpanded(true)
        }
      })
    }
  }, [gridRef, searchInputRef])
}

/**
 * Hook to debounce filter changes.
 * @param gridRef
 * @param searchInputRef
 * @returns A function to debounce filter changes.
 */
export const useDebouncedFilterChange = (
  gridRef: MutableRefObject<AgGridReact | null>,
  searchInputRef: MutableRefObject<string>,
): (() => void) => {
  const expandOnSearch = useExpandOnSearch(gridRef, searchInputRef)

  return useMemo(
    () =>
      debounce(() => {
        if (gridRef.current?.api) {
          gridRef.current.api.onFilterChanged()
          setTimeout(expandOnSearch, 200)
        }
      }, 500),
    [expandOnSearch, gridRef],
  )
}

/**
 * Hook to get a map from node array
 * @param treeData
 * @returns Map of nodes
 */
export const useNodeMap = (
  treeData: GqlOpcUaNode[],
): Map<string, GqlOpcUaNode> => {
  return useMemo(() => {
    return new Map(treeData.map(node => [node.id, { ...node }]))
  }, [treeData])
}

type UseNodeSubscriptions = {
  subscribedNodes: Map<string, GqlOpcUaNode>
  errorNodes: string[]
}

/**
 * Hook to get subscribed nodes and error nodes.
 * @param convertedNodesArray
 * @returns An object with subscribed nodes and error nodes.
 */
export const useNodeSubscriptions = (
  convertedNodesArray: GqlOpcUaNode[],
): UseNodeSubscriptions => {
  const subscribedNodes = useMemo(() => {
    const subscribed = convertedNodesArray.filter(n => n.isSubscribed)
    return new Map(subscribed.map(n => [n.id, n]))
  }, [convertedNodesArray])

  const errorNodes = useMemo(
    () => convertedNodesArray.filter(n => n.lastBrowseError).map(n => n.id),
    [convertedNodesArray],
  )

  return { subscribedNodes, errorNodes }
}

/**
 * Get main column definition
 * @param errorNodes
 * @returns Column definition
 */
export const getMainColumnDef = (errorNodes: string[]): ColDef => {
  return {
    field: 'name',
    headerName: 'Nodes',
    flex: 1000,
    showRowGroup: false,
    filterValueGetter: params => {
      const nodeData = params.data as GqlOpcUaNode & { path: string[] }
      return nodeData.displayName ?? nodeData.browseName
    },
    cellRendererParams: {
      suppressCount: true,
      innerRenderer: (params: ICellRendererParams) => {
        return <GroupColumn params={params} errorNodes={errorNodes} />
      },
    },
  }
}
