import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useModal } from 'react-modal-hook'

import { isOrgAdmin, Organization } from 'src/types'
import { useOrganizations } from 'orgs-sites/org/api'
import { Button, Icon, SearchInput, Text } from 'src/components/ui'
import { orgCompare } from 'orgs-sites/orgsSites.utils'
import { AddOrgUserModal } from 'orgs-sites/users'
import Logo from '../images/logo.svg'

export function OrgList(): JSX.Element {
  const selectedOrgRef = useRef<Organization | null>(null)
  const [filter, setFilter] = useState('')
  const { data: organizations } = useOrganizations()
  const navigate = useNavigate()

  const [showAddOrgUserModal, closeAddOrgUserModal] = useModal(() =>
    selectedOrgRef.current ? (
      <AddOrgUserModal
        org={selectedOrgRef.current}
        onClose={closeAddOrgUserModal}
      />
    ) : null,
  )

  return (
    <>
      <div className="max-w-[200px] pt-s">
        {(organizations?.length ?? 0) > 3 && (
          <SearchInput size="small" value={filter} setValue={setFilter} />
        )}
      </div>

      <div
        className="relative flex min-h-[400px] w-full flex-wrap place-content-start items-start gap-s bg-[top_4em_right_10em] bg-no-repeat pt-s"
        style={{
          backgroundImage: `url(${Logo})`,
        }}
      >
        {organizations
          ?.filter(o => {
            if (!filter) return true
            return o.name.toLowerCase().includes(filter.toLowerCase())
          })
          .sort(orgCompare)
          .map(org => (
            <div
              className={`flex w-[570px] rounded-2xs border border-solid border-border bg-background p-m`}
              key={org.id}
            >
              {org.isHome && (
                <Icon
                  icon={light('house-building')}
                  className="mr-s !h-[30px] !w-[36px] text-icon-secondary"
                />
              )}
              <div style={{ display: 'flex', width: '100%' }}>
                <div className="flex w-full items-start justify-between">
                  <div>
                    <Text variant="description">
                      {org.isHome ? 'Your Home Organization' : 'Organization'}
                    </Text>
                    <Text variant="content" bold className="mb-xs mt-2xs">
                      {org.name}
                    </Text>
                    <Text variant="small" className="mt-s">
                      Users in Organization
                    </Text>
                    <Text variant="description" bold className="mb-xs mt-2xs">
                      {org.userCount}
                    </Text>
                  </div>
                  <div>
                    <Text variant="description">Your Role</Text>
                    <Text variant="content" bold className="mb-xs mt-2xs">
                      {org.viewerRole}
                    </Text>
                    <Text variant="small" className="mt-s">
                      Sites in Organization
                    </Text>
                    <Text variant="description" bold className="mb-xs mt-2xs">
                      {org.numOfSites || 'No sites'}
                    </Text>
                  </div>

                  <div>
                    <Button
                      title="View Details"
                      variant="primary"
                      onClick={() => navigate(`/settings/orgs/${org.id}`)}
                      className="mb-xs"
                    />
                    {isOrgAdmin(org.viewerRole) && (
                      <Button
                        title="Add User"
                        variant="icon-primary"
                        icon={regular('circle-plus')}
                        onClick={() => {
                          selectedOrgRef.current = org
                          showAddOrgUserModal()
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  )
}
