import { Modal, Spinner, Text } from 'src/components/ui'

type Props = {
  isLoading: boolean
  onClose: () => void
  onConfirm: () => void
  tagsAdded: number
  tagsRemoved: number
  isError: boolean
}

export function ConfirmModal({
  isLoading,
  onClose,
  onConfirm,
  tagsAdded,
  tagsRemoved,
  isError,
}: Props): JSX.Element {
  return (
    <Modal
      title={isLoading ? undefined : 'Edit Subscription'}
      isOpen
      close={isLoading ? () => null : onClose}
      footer={
        isLoading ? undefined : (
          <Modal.Footer
            onCancel={onClose}
            onConfirm={onConfirm}
            confirmLabel="Apply Changes"
            type="success"
            buttonDisabled={isLoading}
          />
        )
      }
    >
      {isLoading ? (
        <div className="flex flex-col gap-2xs text-center">
          <Text bold variant="title">
            Applying changes
          </Text>
          <Text variant="description">
            This should only take a couple of seconds
          </Text>
          <Spinner />
        </div>
      ) : (
        <div className="mt-2xs">
          <Text className="max-w-[380px]">
            Are you sure you want to edit your subscription?{' '}
          </Text>
          <div className="my-m flex gap-s">
            <div>
              <Text bold>Tags Added:</Text>
              <Text bold>Tags Removed:</Text>
            </div>
            <div className="text-right">
              <Text>{tagsAdded}</Text>
              <Text>{tagsRemoved}</Text>
            </div>
          </div>
          {isError && (
            <Text variant="description" className="text-text-danger" bold>
              Failed to update the subscription
            </Text>
          )}
        </div>
      )}
    </Modal>
  )
}
