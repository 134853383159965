import { memo } from 'react'
import { motion } from 'framer-motion'
import { TagDto } from 'src/services'
import { ModelTypes } from 'src/types'
import { TagAction } from 'models/create'
import { DynamicTable } from 'src/components/ui'
import { getTagOutputListConfig } from 'models/model/table-configs'

type OutputTagTableProps = {
  factory: string
  modelType?: ModelTypes | null
  tags: TagDto[]
  outputTag?: string | null
  setOutputTag: (tag: string | null) => void
}

export const OutputTagTable = memo(function OutputTagTable({
  factory,
  modelType,
  tags,
  outputTag,
  setOutputTag,
}: OutputTagTableProps): JSX.Element {
  const actions = {
    disallowSelection: !!outputTag,
    allowSearch: !outputTag,
    allowPaging: !outputTag,
    renderAction: ({ data: tag }: any) => {
      if (outputTag) {
        return (
          <TagAction
            type="Display"
            tag={tag}
            tagAction={() => setOutputTag(null)}
          />
        )
      }
      return (
        <TagAction
          type="Set"
          tag={tag}
          tagAction={setOutputTag}
          modelType="AnomalyModel"
        />
      )
    },
  }

  return (
    <div className="flex flex-col" style={{ marginBottom: '0.5em' }}>
      <motion.div
        initial={false}
        transition={{
          duration: 0.2,
        }}
        animate={{
          height: modelType === 'PrescriptiveModel' && outputTag ? 105 : 500,
        }}
      >
        <DynamicTable
          id={`${factory}-OutputStep`}
          key={outputTag}
          data={(
            tags.map(t => ({
              ...t,
              displayName: t.displayName || t.tagName,
              label: t.overriddenLabel || t.automaticLabel,
            })) ?? []
          ).filter(t => !outputTag || t.tagName === outputTag)}
          config={getTagOutputListConfig(actions)}
          fuzzySearch
          fuzzySearchField={['displayName', 'parentName', 'label']}
          className={
            modelType === 'PrescriptiveModel' && outputTag
              ? '!h-[105px]'
              : '!h-full'
          }
          rowHeight={60}
        />
      </motion.div>
    </div>
  )
})
