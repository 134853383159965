import { useEffect, useState } from 'react'
import { useTagData } from 'tags/api'
import {
  NumberInput,
  Text,
  TimePickerNavigation,
  TimeSeriesChart,
} from 'src/components/ui'
import { TagDto } from 'src/services'

type Props = {
  tag: TagDto
  onChange: (value: number) => void
}

export function TargetValueInput({ tag, onChange }: Props): JSX.Element {
  const [value, setValue] = useState<number>()
  const tagData = useTagData({ id: tag.tagName })

  useEffect(() => {
    if (tagData.data && value === undefined) {
      if (tagData.data[0]?.length > 0) {
        const calculatedValue = Math.round(tagData.data[0][1] * 100) / 100
        setValue(calculatedValue)
        onChange(calculatedValue)
      }
    }
  }, [onChange, tagData, value])

  return (
    <div className=" flex flex-col gap-m rounded-xs border border-solid border-border bg-background p-m">
      <div className="flex items-end justify-between">
        <div>
          <Text variant="description" bold>
            Target Value
          </Text>
          <NumberInput
            step={0.1}
            value={value}
            onChange={val => {
              if (val) {
                setValue(val)
                onChange(val)
              }
            }}
          />
        </div>
        <TimePickerNavigation />
      </div>
      <TimeSeriesChart
        id={tag.id}
        data={[{ ...tag, data: tagData.data }]}
        isPending={tagData.isLoading}
        tooltip={true}
        plotLines={
          value
            ? [
                {
                  label: 'Target Value',
                  color: '#000000',
                  value: value,
                },
              ]
            : undefined
        }
      />
    </div>
  )
}
