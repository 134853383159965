import React from 'react'
import { cn } from 'src/utility/shadcn/utils'

const Input = React.forwardRef<HTMLInputElement, React.ComponentProps<'input'>>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          'flex w-full font-400 bg-transparent outline-none border-none placeholder:text-text-disabled disabled:cursor-default placeholder:text-text/50',
          className,
        )}
        ref={ref}
        {...props}
      />
    )
  },
)
Input.displayName = 'Input'

export { Input }
