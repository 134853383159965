import { Card, Text, TimeSeriesChart } from 'src/components/ui'
import { FetchPrescriptiveModel } from 'src/services'
import { useTagData } from 'tags/api'
import { tailwindTheme } from 'pages/app'
import { getThemeObject } from 'src/style/themes'
import { PrescriptiveLegend } from './components'

type Props = {
  model: FetchPrescriptiveModel
}
export function Forecast({ model }: Props): JSX.Element {
  const theme = getThemeObject(tailwindTheme.value)
  const tagData = useTagData({ id: model.tag.tagName })

  const latestValue = tagData.data?.[tagData.data.length - 1]?.[1]
  return (
    <Card className="col-span-2">
      <div className="flex justify-between">
        <div className="flex flex-col gap-3xs">
          <Text className="text-text-secondary">Forecast</Text>
          <Text variant="title" bold>
            {model.tag.displayName ?? model.tag.tagName}
          </Text>
        </div>
        <PrescriptiveLegend
          actualValue={`${latestValue} ${model.tag.engUnit}`}
          targetValue={`${model.targetValue} ${model.tag.engUnit}`}
        />
      </div>
      <TimeSeriesChart
        tooltip
        data={[
          {
            name: 'Actual Value',
            data: tagData.data,
            color: '#726ADF',
          },
          {
            name: 'Target Value',
            data: tagData.data?.map(d => [d[0], model.targetValue]),
            color: theme.text.warning.DEFAULT,
          },
        ]}
        isPending={tagData.isLoading}
      />
    </Card>
  )
}
