import { ColDef, IRowNode } from 'ag-grid-community'
import { Text } from 'src/components/ui'
import { GqlOpcUaNodeClass } from 'src/services'
import { TagMenu } from './components'

export function getHierarchyTreeConfig(
  subscriptionEnabled = false,
  getChangeIndicators: (id: string) => string,
): ColDef[] {
  const changesColumn: ColDef = {
    width: 60,
    maxWidth: 60,
    headerName: '',
    field: 'change',
    colId: 'changelog',
    lockPosition: 'left',
    suppressHeaderMenuButton: true,
    cellRenderer: ({ data }: any) => {
      return <Text bold>{getChangeIndicators(data.id)}</Text>
    },
  }

  const defaultColumns: ColDef[] = [
    {
      field: 'nodeId',
      initialHide: true,
    },
    {
      field: 'dataType',
      initialFlex: 200,
      resizable: true,
      minWidth: 150,
      filter: 'agMultiColumnFilter',
      filterParams: {
        filters: [
          {
            filter: 'agSetColumnFilter',
            filterParams: { buttons: ['clear'] },
          },
        ],
      },
      menuTabs: ['filterMenuTab'],
      cellRenderer: ({ data, value }: any) => {
        if (value) return <Text>{value}</Text>
        if (data.class === GqlOpcUaNodeClass.Variable)
          return <Text>unknown</Text>
        return ''
      },
    },
    {
      field: 'actions',
      resizable: false,
      minWidth: 60,
      maxWidth: 60,
      cellClass: 'selector-cell',
      headerComponent: () => null,
      cellRenderer: ({ data }: { data: any; node: IRowNode }) => {
        return <TagMenu tagName={data.nodeId} />
      },
    },
  ]

  if (subscriptionEnabled) return [changesColumn, ...defaultColumns]

  return defaultColumns
}
