import { Theme, themeAlpine } from 'ag-grid-community'

export const tableTheme: Theme = themeAlpine.withParams({
  fontFamily: ['Roboto', 'Arial', 'Helvetica', 'sans-serif'],
  accentColor: '#00DC00',
  menuBackgroundColor: '#FFFFFF',
  oddRowBackgroundColor: '#FFFFFF',
  selectedRowBackgroundColor: '#CCF8CC88',
  borderColor: '#E0E0E0',
  headerBackgroundColor: '#FFFFFF',
  checkboxUncheckedBorderColor: '#333333',
})
