import { useRef } from 'react'
import { Field, Form, Formik, FormikProps } from 'formik'
import * as Yup from 'yup'
import classNames from 'classnames'
import { Button, Modal, Text } from 'src/components/ui'
import { GqlFactoryTrendViewFragment } from 'src/services'
import { useCharts } from 'src/contexts/charts'

type Props = {
  view?: GqlFactoryTrendViewFragment
  isModalOpen: boolean
  onCancel: () => void
  onDiscard: () => void
  onSave: ({ name, shared }: FormValues) => void
  canEdit: boolean
  currentUserId: string
  views: GqlFactoryTrendViewFragment[]
}

type FormValues = {
  name: string
  shared: boolean
}

const FormSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  discoverable: Yup.boolean(),
})

export function UnsavedChangesModal({
  view,
  isModalOpen,
  onCancel,
  onDiscard,
  onSave,
  canEdit,
  currentUserId,
  views,
}: Props): JSX.Element {
  const formikRef = useRef<FormikProps<FormValues>>(null)
  const { isViewChanged } = useCharts()

  function validateName(name: string): string | undefined {
    if (name === view?.name && currentUserId === view?.user.id) return undefined
    const isNameExists = views.some(view => view.name === name)
    if (isNameExists) {
      return 'There is already a view with this name.'
    }
    return undefined
  }

  return (
    <Formik
      initialValues={{
        name: view && currentUserId === view.user.id ? view?.name : '',
        shared: view && currentUserId === view.user.id ? view?.shared : false,
      }}
      enableReinitialize
      validationSchema={FormSchema}
      onSubmit={({ name, shared }) => onSave({ name, shared })}
      innerRef={formikRef}
    >
      {({ errors, touched, isValid, values }) => (
        <Modal
          title="Unsaved Changes"
          isOpen={isModalOpen}
          close={onCancel}
          footer={
            <div className="flex w-full items-center justify-end pt-s">
              <div className="flex items-center gap-xs">
                <Button
                  variant="secondary"
                  title="Save"
                  onClick={() => onSave(values)}
                  disabled={
                    !isValid ||
                    (!canEdit && view?.name === values.name) ||
                    !values.name ||
                    (view &&
                      !isViewChanged &&
                      view.name === values.name &&
                      view.shared === values.shared)
                  }
                />
                <Button
                  variant="secondary"
                  title="Don't Save"
                  buttonColor="danger"
                  onClick={onDiscard}
                />
              </div>
            </div>
          }
          closeIconSize="regular"
        >
          <>
            <Form className="mb-s max-w-[320px]">
              <Text className="mt-xs">
                Trend view has not been saved since updating. What do you want
                to do before leaving the page?
              </Text>
              <div className="my-m flex items-center gap-xs">
                <Text bold className="whitespace-nowrap">
                  Save Trend View as
                </Text>
                <div className="relative w-full">
                  <Field
                    validate={validateName}
                    name="name"
                    placeholder="Name your view"
                    className={classNames(
                      'flex w-full items-center rounded-2xs border-none pl-xs text-xs font-medium leading-[32px] outline-none ring-1 ring-offset-1 placeholder:font-thin',
                      errors.name && touched.name
                        ? 'bg-background-danger-tertiary-hover ring-border-danger text-text-danger'
                        : 'ring-grey-disabled',
                    )}
                  ></Field>
                  {errors.name && (
                    <Text
                      variant="small"
                      className="absolute -bottom-icon-big left-0 text-text-danger"
                    >
                      {errors.name && touched.name ? errors.name : null}
                    </Text>
                  )}
                </div>
              </div>
            </Form>
          </>
        </Modal>
      )}
    </Formik>
  )
}
