import { Text, Button } from 'src/components/ui'
import { useTag } from 'tags/api'
import { Record } from '../steps'
import {
  DisplayInputTags,
  AddInputTagsModal,
  ControllableInputTagsSelector,
} from './components'

interface InputStepProps {
  isAddInputTagsModalOpen?: boolean
  setIsAddInputTagsModalOpen?: (isOpen: boolean) => void
  outputTag?: string | null
  inputTags?: string[]
  controllableTags?: string[]
  model: Record
  addInputTag: (tag: string) => void
  removeInputTag: (tag: string) => void
  setControllableTag: (tag: string, controllable: boolean) => void
}

export function InputStep({
  isAddInputTagsModalOpen: isOpen,
  setIsAddInputTagsModalOpen: setIsOpen,
  outputTag,
  inputTags,
  controllableTags,
  addInputTag,
  removeInputTag,
  setControllableTag,
  model,
}: InputStepProps): JSX.Element {
  const { data: tag } = useTag(outputTag ?? '')

  return (
    <>
      <Text variant="title" bold className="my-m text-center !text-3xl">
        Which values are good predictors of{' '}
        <span>{tag?.displayName || 'the output'}</span>?
      </Text>
      <Text className="my-s">
        A good model requires a set of input tags that can explain how the
        output tag changes over time. Select the input tags that help explain
        how the output tag changes over time.
      </Text>
      {model.modelType === 'ForecastModel' && (
        <Text className="mb-m">
          <span className="font-500">{model.outputTag} </span> has been added.
          Forecast models usually perform better with output as input.
        </Text>
      )}
      {model.modelType === 'PrescriptiveModel' ? (
        <ControllableInputTagsSelector
          inputTags={inputTags}
          controllableTags={controllableTags}
          setControllableTag={setControllableTag}
          removeInputTag={removeInputTag}
        />
      ) : (
        <>
          <DisplayInputTags
            inputTags={inputTags}
            removeInputTag={removeInputTag}
            model={model}
          />
          <Button
            title="+ Add Input Tag"
            variant="primary"
            className="mb-[0.5em]"
            onClick={() => setIsOpen?.(true)}
          />
        </>
      )}
      {isOpen && (
        <AddInputTagsModal
          isOpen={isOpen}
          setIsOpen={isOpen => setIsOpen?.(isOpen)}
          inputTags={inputTags}
          addInputTag={addInputTag}
          removeInputTag={removeInputTag}
          model={model}
        />
      )}
    </>
  )
}
