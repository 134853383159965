import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { useState } from 'react'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { RUNNING } from 'models/model/model.utils'
import { Card, Icon, Text } from 'src/components/ui'
import { FetchPrescriptiveModel, ModelInputTag } from 'src/services'
import { useTimeSeriesData } from 'trend/api'
import { useLatestValueTimeRange } from '../../prescriptive-model.utils'
import { Chart } from './Chart'

type Props = {
  model: FetchPrescriptiveModel
  uncontrollableInputTags: ModelInputTag[]
}

export function OtherFactors({
  model,
  uncontrollableInputTags,
}: Props): JSX.Element {
  const [expandedTags, setExpandedTags] = useState<string[]>([])
  const timeRange = useLatestValueTimeRange()
  const tagsData = useTimeSeriesData(
    uncontrollableInputTags.map(t => ({ id: t.node.tagName, type: 'tag' })),
    timeRange,
  )

  const handleExpand = (tagName: string): void => {
    if (expandedTags.includes(tagName)) {
      setExpandedTags(expandedTags.filter(tag => tag !== tagName))
    } else {
      setExpandedTags([...expandedTags, tagName])
    }
  }
  return (
    <div className="col-span-2 flex flex-col gap-s">
      <div className="flex flex-col gap-2xs">
        <Text variant="title" bold>
          Other factors
        </Text>
        <Text>
          Informative inputs will contribute, but can not be directly altered.{' '}
        </Text>
      </div>
      <Card>
        <div className="flex flex-col">
          <div className="mr-[32px] flex items-center justify-between">
            <Text bold>Informative Tags</Text>
            <Text bold>Current Value</Text>
          </div>
          {uncontrollableInputTags.map((tag, index) => {
            const { data } = tagsData[index]
            const currentValue = data ? data[data.length - 1][1] : undefined
            return (
              <div
                className={classNames(
                  index === 0 && 'pt-xs',
                  index !== uncontrollableInputTags.length - 1 &&
                    'border-0 border-b border-solid border-border',
                )}
                key={tag.node.tagName}
              >
                <div
                  onClick={() => handleExpand(tag.node.tagName)}
                  className={classNames(
                    'flex items-center justify-between cursor-pointer hover:bg-background-hover transition duration-300',
                    'py-[10px]',
                  )}
                >
                  <Text className="text-right text-text-secondary">
                    {tag.node.displayName ?? tag.node.tagName}
                  </Text>
                  <div className="flex items-center gap-s">
                    <Text
                      className={classNames('text-text-secondary text-right')}
                    >
                      {currentValue !== undefined
                        ? Math.round(currentValue * 100) / 100
                        : 'N/A'}
                    </Text>
                    <Icon
                      icon={light('chevron-down')}
                      className={
                        expandedTags.includes(tag.node.tagName)
                          ? 'rotate-180'
                          : ''
                      }
                    />
                  </div>
                </div>

                <AnimatePresence>
                  {expandedTags.includes(tag.node.tagName) && (
                    <motion.div
                      // slide down
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -10 }}
                      transition={{ duration: 0.1 }}
                      className="py-m"
                    >
                      <Chart tag={tag.node} />
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            )
          })}
        </div>
      </Card>
    </div>
  )
}
