import { isAfter, parseISO } from 'date-fns'
import { useCallback, useEffect } from 'react'
import toast from 'react-hot-toast'
import {
  getSubscriptions,
  removeSubscription,
  updateSubscription,
} from 'src/utility/subscriptionHelper'
import * as api from 'src/services'
import { useFetchModelMutation } from './subscription.api'

const REFETCH_INTERVAL = 1000 * 60

export function Subscriptions(): null {
  const { mutateAsync: fetchModel } = useFetchModelMutation()
  const handleSubscriptions = useCallback(async () => {
    const subscriptions = getSubscriptions()
    // iterate over each subscription
    for (const [key, { expire, event, lastState }] of Object.entries(
      subscriptions,
    )) {
      // if the subscription has expired, remove it
      if (isAfter(new Date(), parseISO(expire))) {
        removeSubscription(key)
        continue
      }

      await fetchModel(
        {
          modelId: key,
          currentState: lastState as api.GqlModelState,
        },
        {
          onSuccess: res => {
            // if the model has been deleted or reached error stete , remove the subscription
            if (!res || res.state === 'DELETED' || res.state === 'ERROR') {
              removeSubscription(key)
              return
            }

            // if the model has reached the desired state, trigger toast and remove the subscription
            if (res.state === event) {
              const forecastModel = res.__typename === 'ForecastModel'

              let message = `Successfully trained model ${res.name}`
              if (!forecastModel && event === 'NOT_RUNNING') {
                message = `Successfully identified anomalies for the model ${res.name}`
              }

              toast.success(message, {
                position: 'top-right',
              })

              // remove the subscription from local storage
              // invalidate the queries to refetch the data
              removeSubscription(res.id)
            } else if (res.state !== lastState) {
              updateSubscription(key, res.state)
            }
          },
        },
      )
    }
  }, [fetchModel])

  useEffect(() => {
    const interval = setInterval(() => {
      handleSubscriptions()
    }, REFETCH_INTERVAL)
    return () => {
      clearInterval(interval)
    }
  }, [handleSubscriptions])

  return null
}
