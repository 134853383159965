import { Text } from 'src/components/ui'

type Props = {
  actualValue: string
  targetValue: string
}

export function PrescriptiveLegend({
  actualValue,
  targetValue,
}: Props): JSX.Element {
  return (
    <div className="flex gap-s">
      <div className="flex flex-col items-end">
        <div className="flex items-center gap-2xs">
          <div className="size-s rounded-[2px] bg-[#726ADF]" />
          <Text className="text-text-secondary">Actual value</Text>
        </div>
        <Text bold>{actualValue}</Text>
      </div>
      <div className="flex flex-col items-end">
        <div className="flex items-center gap-2xs">
          <div className="size-s rounded-[2px] bg-text-warning" />
          <Text className="text-text-secondary">Target value</Text>
        </div>
        <Text bold>{targetValue}</Text>
      </div>
    </div>
  )
}
