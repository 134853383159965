import { useState } from 'react'
import toast from 'react-hot-toast'
import { DynamicTable, Spinner } from 'src/components/ui'
import { SiteInvitation } from 'src/types'
import { RejectInvitationModal } from 'orgs-sites/users'
import {
  useAcceptSiteInvitation,
  useDeclineSiteInvitation,
  useUserInvitations,
} from 'site/api'
import { parseApiErrorMessage } from 'src/utility/errors'
import { ErrorDisplay } from 'pages/app'
import { invitationsTableConfig } from './SiteInvitations.table.config'

export function SiteInvitations(): JSX.Element {
  const invitationQuery = useUserInvitations()

  if (invitationQuery.isLoading) {
    return <Spinner />
  }

  if (invitationQuery.isError) {
    return (
      <ErrorDisplay
        error={invitationQuery.error}
        action={invitationQuery.refetch}
      />
    )
  }
  return <InvitationTable invitations={invitationQuery.data} />
}

type InvitationTableProps = {
  invitations: SiteInvitation[]
}

function InvitationTable({ invitations }: InvitationTableProps): JSX.Element {
  const acceptInvitationMutation = useAcceptSiteInvitation()
  const declineInvitationMutation = useDeclineSiteInvitation()
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const [selectedInvitation, setSelectedInvitation] = useState<
    SiteInvitation | undefined
  >(undefined)

  const declineInvite = async (): Promise<void> => {
    if (selectedInvitation) {
      await declineInvitationMutation.mutateAsync(
        { invitationId: selectedInvitation.id },
        {
          onSuccess: () => {
            setConfirmModalOpen(false)
          },
        },
      )
    }
  }

  const actions = {
    acceptInvitation: async ({ id }: any) => {
      await acceptInvitationMutation.mutateAsync(
        { invitationId: id },
        {
          onSuccess: () => {
            toast.success('Invitation accepted.', { position: 'top-right' })
          },
          onError: () => {
            toast.error('Failed to accept invitation.', {
              position: 'top-right',
            })
          },
        },
      )
    },
    openConfirmModal: ({ id }: any) => {
      setSelectedInvitation(invitations.find(invite => invite.id === id))
      setConfirmModalOpen(true)
    },
  }
  return (
    <div className="mt-[1em] flex-1">
      <DynamicTable
        id="SiteInvitations"
        data={invitations.sort((a, b) =>
          a.validUntil > b.validUntil ? -1 : 1,
        )}
        config={invitationsTableConfig(actions)}
      />
      <RejectInvitationModal
        onConfirm={declineInvite}
        disabled={declineInvitationMutation.isLoading}
        error={
          declineInvitationMutation.error
            ? parseApiErrorMessage(
                declineInvitationMutation.error,
                'Failed to decline invitation.',
              )
            : undefined
        }
        orgName={selectedInvitation?.site.orgName || ''}
        siteName={selectedInvitation?.site.siteName || ''}
        isOpen={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
      />
    </div>
  )
}
