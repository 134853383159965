import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

type AnomalyFilters = {
  filter: string
  statuses: string[]
  favoriteModels: boolean
  setFilter: (filter: string) => void
  setStatuses: (value: string[]) => void
  setFavoriteModels: (favoriteModels: boolean) => void
}

export function useAnomaliesFilter(
  defaultStatuses: string[] = [],
): AnomalyFilters {
  const [search, setSearch] = useSearchParams()

  useEffect(() => {
    if (defaultStatuses && !search.has('status')) {
      search.set('status', defaultStatuses.join(','))
      setSearch(search)
    }
  }, [defaultStatuses, search, setSearch])

  function setFilter(filter: string): void {
    if (filter === '') {
      search.delete('filter')
    } else {
      search.set('filter', filter)
    }
    setSearch(search)
  }

  function setStatuses(value: string[]): void {
    search.set('status', value.join(','))
    setSearch(search)
  }

  function setFavoriteModels(favoriteModels: boolean): void {
    search.set('favoriteModels', favoriteModels.toString())
    setSearch(search)
  }

  const statusFilter = search.get('status')
  const favouriteModels = search.get('favoriteModels')

  return {
    filter: search.get('filter') || '',
    statuses: statusFilter ? statusFilter.split(',') : [],
    favoriteModels: favouriteModels === 'true',
    setFilter,
    setStatuses,
    setFavoriteModels,
  }
}
