import { useMemo } from 'react'
import { sub } from 'date-fns'
import {
  useQuery,
  useQueries,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import * as api from 'src/services'
import { TimeRange, TimeSeriesData } from 'src/types'
import useTimeRange from 'src/contexts/timeRange'
import { processTimeSeries } from 'src/utility/timeSeries'
import { timeSeriesRefetch } from 'trend/api'
import { useSite } from 'src/contexts/site'
import { useTagData } from 'tags/api'
import { useIsTabVisible } from 'src/utility'

type HistoricalForecastProps = {
  modelId: string
  offsetInMilliseconds: number
  timeRange: TimeRange
  enabled?: boolean
}

export function historicalForecastQuery({
  modelId,
  offsetInMilliseconds,
  timeRange,
  enabled = true,
}: HistoricalForecastProps): UseQueryOptions<TimeSeriesData[]> {
  return {
    queryKey: [
      'historicalForecast',
      modelId,
      offsetInMilliseconds,
      timeRange.from,
      timeRange.to,
    ],
    queryFn: async () => {
      const data = await api.fetchHistoricalForecast({
        modelId,
        offsetInMilliseconds,
        timeRange,
      })
      return data.data.map(({ timestamp, avg, min, max }) => [
        timestamp.getTime(),
        avg,
        min,
        max,
      ])
    },
    enabled,
    refetchInterval: () => timeSeriesRefetch(timeRange),
  }
}

interface UseHistoricalForecast {
  modelId: string
  offsetInMilliseconds: number
}

export function useHistoricalForecast({
  modelId,
  offsetInMilliseconds,
}: UseHistoricalForecast): UseQueryResult<TimeSeriesData[]> {
  const { timeRange } = useTimeRange()
  const isTabVisible = useIsTabVisible()
  const query = historicalForecastQuery({
    modelId,
    offsetInMilliseconds,
    timeRange,
    enabled: isTabVisible,
  })
  return useQuery(query)
}

export type Forecast = {
  tagName: string
  madeAt: number
  prediction: TimeSeriesData[]
}

function modelForecastQuery(req: api.FetchForecast): UseQueryOptions<Forecast> {
  return {
    queryKey: ['modelForecast', req],
    queryFn: async () => {
      const { tagName, madeAt, prediction } = await api.fetchForecast(req)
      return {
        tagName,
        madeAt: madeAt.valueOf(),
        prediction: processTimeSeries(prediction),
      }
    },
  }
}

type ForecastData = {
  prediction?: TimeSeriesData[] | undefined
  tagData?: TimeSeriesData[] | undefined
}

export function useModelForecast(modelId: string): [ForecastData, boolean] {
  const { id: factory } = useSite()
  const isTabVisible = useIsTabVisible()
  const query = modelForecastQuery({ modelId, factory })
  const modelForecast = useQuery({
    ...query,
    enabled: isTabVisible,
    // Refetch the forecast every minute
    refetchInterval: isTabVisible && 1000 * 60,
  })

  const forecast = modelForecast.data

  const timeRange = useMemo(() => {
    const madeAt = forecast?.madeAt ?? Date.now()
    return {
      from: sub(madeAt, { hours: 3 }).valueOf(),
      to: madeAt,
    }
  }, [forecast?.madeAt])

  const tagDataProps = forecast
    ? { id: forecast.tagName, timeRange }
    : undefined

  // this will refetch when the props change.
  const tagData = useTagData(tagDataProps)

  return [
    {
      prediction: forecast?.prediction,
      tagData: tagData.data,
    },
    modelForecast.isLoading || tagData.isLoading,
  ]
}

export function useForecasts(ids: string[]): UseQueryResult<Forecast>[] {
  const { id: factory } = useSite()
  const queries = ids.map(modelId => modelForecastQuery({ modelId, factory }))
  return useQueries({ queries })
}
