import { datadogRum } from '@datadog/browser-rum'
import { useState } from 'react'
import { merge } from 'lodash'
import {
  ChartAxis,
  ChartOptions,
  ChartType,
  getChartTypeLabel,
  SeriesOptions,
  TableComponentLayout,
} from 'src/types/chartTypes'
import {
  Button,
  Modal,
  Text,
  Tabs,
  TimePickerNavigation,
} from 'src/components/ui'
import { useSite } from 'src/contexts/site'
import { removeSeries } from 'src/contexts/charts'
import { ForecastsTable, AnomalyModelsTable, TagsTable } from '../Tables'
import ChartPreview from '../Chart/ChartPreview'
import { AddChartType } from '../../trend.types'
import { AddChartButton } from './components'

type EditModalProps = {
  isEdit: true
  chart: ChartOptions
}

type AddModelProps = {
  isEdit: false
  id: number
  chartType?: ChartType
}

type ModalProps = (AddModelProps | EditModalProps) & {
  close: () => void
  onAddChart: (chart: ChartOptions) => void
}

export function AddChartModal({
  close,
  onAddChart,
  ...rest
}: ModalProps): JSX.Element {
  const { isEdit } = rest
  const [previewChart, setPreviewChart] = useState<ChartOptions>(
    isEdit
      ? rest.chart
      : { id: rest.id, data: [], type: rest.chartType ?? ChartType.TimeSeries },
  )

  const tabs: Record<string, TableComponentLayout> = {
    Tags: TagsTable,
  }

  const { isWorkshop } = useSite()

  if (!isWorkshop) {
    tabs.Forecasts = ForecastsTable
    tabs['Anomaly Models'] = AnomalyModelsTable
  }

  const setPreviewOptions = (
    index: number,
    options: Partial<SeriesOptions>,
  ): void => {
    setPreviewChart({
      ...previewChart,
      data: previewChart.data.map((opt, i) => {
        if (i === index) {
          const mergedOpt = merge({ ...opt }, options)
          if (Object.keys(options).includes('min') && options.min === undefined)
            mergedOpt.min = undefined
          if (Object.keys(options).includes('max') && options.max === undefined)
            mergedOpt.max = undefined
          return mergedOpt
        }
        return opt
      }),
    })
  }

  const handleAddSeries = (
    props: SeriesOptions,
    position?: ChartAxis,
  ): void => {
    if (previewChart.type === ChartType.Scatter) {
      if (position === undefined) {
        throw new Error('position undefined when adding to scatter chart')
      }

      // Filter out the series with the same axis
      const filteredData = previewChart.data.filter(d => d.axis !== position)
      setPreviewChart({
        ...previewChart,
        data: [...filteredData, { ...props, axis: position }],
      })
    } else {
      // for time series, just add
      setPreviewChart({
        ...previewChart,
        data: [...previewChart.data, props],
      })
    }
  }

  const showMultipleAdd = !isEdit && previewChart.type !== ChartType.Scatter

  return (
    <Modal
      isOpen={true}
      close={close}
      customStyles="flex flex-col flex-1 h-[calc(100vh-32px)] !py-0 relative"
      contentClassName="flex-1"
    >
      <div className="flex flex-1 flex-col">
        <div className="flex items-center justify-between p-s pb-xs">
          <Text variant="title" bold>
            {isEdit
              ? `Edit ${getChartTypeLabel(previewChart.type)}`
              : `Add ${getChartTypeLabel(previewChart.type)}`}
          </Text>
          <TimePickerNavigation />
        </div>
        <div className="flex w-full flex-1 px-s">
          <div className="flex max-w-[40%] flex-1 flex-col overflow-auto pb-xs">
            <Tabs
              tabs={Object.keys(tabs)}
              renderContent={activeTab => {
                const Table = tabs[activeTab]
                return (
                  <Table
                    chart={previewChart}
                    addSeries={handleAddSeries}
                    removeSeries={props =>
                      setPreviewChart(chart => removeSeries(chart, props))
                    }
                  />
                )
              }}
            />
          </div>
          <div className="flex w-3/5 flex-1 flex-col justify-between">
            {previewChart.data.length ||
            previewChart.type === ChartType.Scatter ? (
              <ChartPreview
                chart={previewChart}
                setPreviewOptions={setPreviewOptions}
                removeSeries={props =>
                  setPreviewChart(chart => removeSeries(chart, props))
                }
                setPreviewChart={setPreviewChart}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="mx-s flex flex-row-reverse items-center gap-s border-0 border-t border-solid border-border py-xs">
          {showMultipleAdd ? (
            <AddChartButton
              onClick={type => {
                if (type === AddChartType.Single) {
                  datadogRum.addAction('Trend chart added', {
                    chartType: 'single',
                  })
                  onAddChart(previewChart)
                } else {
                  datadogRum.addAction('Trend chart added', {
                    chartType: 'multiple',
                  })
                  previewChart.data.forEach((data, index) => {
                    onAddChart({
                      ...previewChart,
                      id: previewChart.id + index,
                      data: [data],
                    })
                  })
                }
                close()
              }}
              disabled={previewChart.data.length === 0}
            />
          ) : (
            <Button
              variant="primary"
              title="OK"
              disabled={
                (previewChart.type === ChartType.TimeSeries &&
                  previewChart.data.length === 0) ||
                (previewChart.type === ChartType.Scatter &&
                  previewChart.data.length !== 2)
              }
              onClick={() => {
                onAddChart(previewChart)
                close()
              }}
            />
          )}
          <Button variant="tertiary" title="Cancel" onClick={close} />
        </div>
      </div>
    </Modal>
  )
}
