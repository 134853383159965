import React, { useEffect, useState } from 'react'
import SortableTree from '@nosferatu500/react-sortable-tree'
import '@nosferatu500/react-sortable-tree/style.css'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'

import { ASSET_VER_STATUS } from 'src/utility/constants/AssetConst'
import { Asset, assetTitle, SiteRole } from 'src/types'
import { Button, Icon, SearchInput, Text } from 'src/components/ui'
import { useSite } from 'src/contexts/site'
import { AssetItem } from '../assets.types'
import { RootPortal } from './RootPortal'
import { ContextMenu, ContextOption, ContextTitle } from './contextMenu'
import { FilterTag } from './FilterTag'
import {
  Body,
  CollapseButtonWrapper,
  NavigationButton,
  NavigationContainer,
  ItemFoundContainer,
} from './AssetHierarchy.sc'
import { AssetNodeRenderer } from './AssetNodeRenderer'

interface AssetsHierarchyProps {
  treeData: AssetItem[]
  selectedAsset?: Asset
  selectAsset: (asset: Asset) => void
  addMovedAsset: (asset: Asset) => void
  setTreeState: (tree: AssetItem[]) => void
  getNodeKey: (node: any) => string
  onExpandToggle: (asset: Asset, expanded: boolean) => void
  onClickCollapseAll: () => void
  handleUpdateDrop: (tags: any[]) => void

  // Context Menu
  onClickAddChildAsset: (asset: Asset) => void
  onClickDeleteAsset: (asset: Asset) => void
  onClickChangeAssetName: (asset: Asset) => void
}

type ShowAssetContextMenu = {
  show: true
  top: number
  left: number
  asset: Asset
}

type HideAssetContextMenu = {
  show: false
}

type AssetContextMenu = ShowAssetContextMenu | HideAssetContextMenu

export function AssetsHierarchy({
  selectedAsset,
  setTreeState,
  getNodeKey,
  onClickCollapseAll,
  onClickChangeAssetName,
  onClickAddChildAsset,
  onClickDeleteAsset,
  onExpandToggle,
  selectAsset,
  treeData,
  addMovedAsset,
  handleUpdateDrop,
}: AssetsHierarchyProps): JSX.Element {
  const [value, setValue] = useState('')
  const [searchFocusIndex, setSearchFocusIndex] = useState(0)
  const [searchFoundCount, setSearchFoundCount] = useState(0)
  const [contextMenu, setContextMenu] = useState<AssetContextMenu>({
    show: false,
  })
  const { viewerRole: role } = useSite()

  useEffect(() => {
    const onClickWindow = (): void => setContextMenu({ show: false })
    document.addEventListener('click', onClickWindow)
    return () => document.removeEventListener('click', onClickWindow)
  }, [])

  const onClickNode = (asset: Asset): void => selectAsset(asset)

  const onContextMenu = (
    event: React.MouseEvent<HTMLDivElement>,
    asset: Asset,
  ): void => {
    const { pageY, pageX } = event

    event.preventDefault()
    event.persist()

    setContextMenu({ show: true, top: pageY, left: pageX, asset })
  }

  const selectPrevMatch = (): void =>
    setSearchFocusIndex(
      searchFocusIndex !== null
        ? (searchFoundCount + searchFocusIndex - 1) % searchFoundCount
        : searchFoundCount - 1,
    )

  const selectNextMatch = (): void =>
    setSearchFocusIndex(
      searchFocusIndex !== null ? (searchFocusIndex + 1) % searchFoundCount : 0,
    )

  return (
    <Body>
      <CollapseButtonWrapper>
        <Button
          variant="icon-primary"
          icon={light('minus-square')}
          title="Collapse All"
          onClick={onClickCollapseAll}
        />
      </CollapseButtonWrapper>
      <div className="flex items-center justify-between gap-s px-xs">
        <SearchInput
          size="small"
          containerClassName="my-xs w-full"
          setValue={setValue}
          value={value}
        />
        <NavigationContainer>
          <NavigationButton type="button" disabled={!searchFoundCount}>
            <Icon onClick={selectPrevMatch} icon={light('arrow-circle-left')} />
          </NavigationButton>
          <NavigationButton type="submit" disabled={!searchFoundCount}>
            <Icon
              onClick={selectNextMatch}
              icon={light('arrow-circle-right')}
            />
          </NavigationButton>

          <ItemFoundContainer>
            &nbsp;
            <span>{searchFoundCount > 0 ? searchFocusIndex + 1 : 0}</span>
            &nbsp;of&nbsp;
            <span>{searchFoundCount || 0}</span>
          </ItemFoundContainer>
        </NavigationContainer>
      </div>

      <SortableTree
        className="assetVerificationTree"
        treeData={treeData}
        onChange={setTreeState}
        canDrag={() => role === SiteRole.ADMIN}
        onVisibilityToggle={({ node, expanded }) =>
          onExpandToggle(node.asset, expanded)
        }
        getNodeKey={getNodeKey}
        onlyExpandSearchedNodes={true}
        onMoveNode={({ node, nextParentNode }) => {
          const movedNode = {
            ...node.asset,
            parentKey: nextParentNode ? nextParentNode.asset.key : 0,
          }

          addMovedAsset(movedNode)
        }}
        style={{
          width: '100%',
          height: '100%',
          border: '1px solid rgba(224, 224, 224, 1)',
          borderRadius: '5px',
        }}
        searchQuery={value}
        searchMethod={query => {
          const { searchQuery, node } = query

          if (searchQuery) {
            const title = assetTitle(node.asset).toLowerCase()
            return title.includes(searchQuery.toLowerCase())
          }
          return false
        }}
        searchFocusOffset={searchFocusIndex}
        searchFinishCallback={matches => {
          setSearchFoundCount(matches.length)
          setSearchFocusIndex(
            matches.length > 0 ? searchFocusIndex % matches.length : 0,
          )
        }}
        rowHeight={30}
        nodeContentRenderer={AssetNodeRenderer}
        generateNodeProps={rowInfo => {
          return {
            buttons: [
              rowInfo.node.asset.active === false && (
                <FilterTag
                  color={ASSET_VER_STATUS[1].color}
                  value={ASSET_VER_STATUS[1].value}
                  small={true}
                />
              ),
            ],
            handleTableDrop: handleUpdateDrop,
            // TODO: handle warnings somehow, not critical warnings
            onClickNode,
            isSelected:
              selectedAsset && rowInfo.node.asset.key === selectedAsset.key,
            onContextMenu,
          }
        }}
      />
      {contextMenu.show && role === SiteRole.ADMIN && (
        <RootPortal>
          <ContextMenu top={contextMenu.top} left={contextMenu.left}>
            <>
              <ContextTitle>
                <Text bold>{assetTitle(contextMenu.asset)}</Text>
              </ContextTitle>
              <ContextOption
                className="flex items-center gap-xs"
                onClick={() => onClickChangeAssetName(contextMenu.asset)}
              >
                <Icon icon={light('edit')} />
                <Text>
                  {contextMenu.asset.alias ? 'Change Alias' : 'Give Alias'}
                </Text>
              </ContextOption>

              <ContextOption
                className="flex items-center gap-xs"
                onClick={() => onClickAddChildAsset(contextMenu.asset)}
              >
                <Icon icon={light('plus-circle')} />
                <Text>Add Child Asset</Text>
              </ContextOption>
              <ContextOption
                className="flex items-center gap-xs"
                onClick={() => onClickDeleteAsset(contextMenu.asset)}
              >
                <Icon icon={light('trash')} />
                <Text>Delete Asset</Text>
              </ContextOption>
            </>
          </ContextMenu>
        </RootPortal>
      )}
    </Body>
  )
}
