import { Text, Spinner, Banner, Button } from 'src/components/ui'
import { useSite } from 'src/contexts/site'
import { useTags } from 'tags/api'
import { ModelTypes } from 'src/types'
import { TagDto } from 'src/services'
import { Record } from '../steps'
import {
  DisplayOutputTag,
  OutputTagTable,
  TargetValueInput,
} from './components'
import { getTitle } from './output.utils'

interface OutputStepProps {
  modelType?: ModelTypes | null
  outputTag?: string | null
  setOutputTag: (tag: string | null) => void
  setTargetValue: (value: number) => void
  model: Record
}

export function OutputStep({
  modelType,
  outputTag,
  setOutputTag,
  setTargetValue,
  model,
}: OutputStepProps): JSX.Element {
  const tagsQuery = useTags()

  if (tagsQuery.isLoading) {
    return <Spinner />
  }

  if (tagsQuery.isError) {
    return (
      <Banner
        variant="error"
        className="my-s"
        rightComponent={
          <Button
            variant="primary"
            onClick={() => tagsQuery.refetch()}
            title="Try again"
          />
        }
      >
        Failed to load tags
      </Banner>
    )
  }

  return (
    <OutputStepWithData
      modelType={modelType}
      outputTag={outputTag}
      setOutputTag={setOutputTag}
      setTargetValue={setTargetValue}
      model={model}
      tags={tagsQuery.data}
    />
  )
}

function OutputStepWithData({
  modelType,
  outputTag,
  setOutputTag,
  setTargetValue,
  model,
  tags,
}: OutputStepProps & { tags: TagDto[] }): JSX.Element {
  const { id: factory } = useSite()
  const selectedTag = tags.find(t => t.tagName === outputTag)

  return (
    <>
      <Text variant="title" bold className="my-m text-center !text-3xl">
        {getTitle(model)}
      </Text>
      {modelType === 'AnomalyModel' && (
        <Text className="my-s">
          This is the tag you are trying to detect anomalies on. Anomalies will
          be flagged when something unexpected happens in the value of this tag.
        </Text>
      )}
      {outputTag && !(modelType === 'PrescriptiveModel') ? (
        <DisplayOutputTag outputTag={outputTag} setOutputTag={setOutputTag} />
      ) : (
        <OutputTagTable
          factory={factory}
          modelType={model.modelType}
          tags={tags}
          outputTag={outputTag}
          setOutputTag={setOutputTag}
        />
      )}

      {outputTag && selectedTag && modelType === 'PrescriptiveModel' && (
        <div className="mb-m">
          <TargetValueInput tag={selectedTag} onChange={setTargetValue} />
        </div>
      )}
    </>
  )
}
