import { useModelHistoricalPrediction } from 'pages/site/models/api'
import { useTagData } from 'pages/site/tags/api'
import { Text, TimeSeriesChart } from 'src/components/ui'
import { GqlModelInputTagDetailsFragment } from 'src/services'
import { getThemeObject } from 'src/style/themes'
import { tailwindTheme } from 'pages/app'
import { getGraphColor } from 'src/utility/graphUtils/graphColors'

type Props = {
  modelId: string
  tag: GqlModelInputTagDetailsFragment
  recommendedValue?: number
}

export function TagChart({
  modelId,
  tag,
  recommendedValue,
}: Props): JSX.Element {
  const theme = getThemeObject(tailwindTheme.value)
  const tagDataQuery = useTagData({ id: tag.tagName })
  const historicalPrediction = useModelHistoricalPrediction({
    modelId,
    tagId: tag.id,
  })
  const color = getGraphColor(tag.tagName)
  return (
    <div className="flex size-full max-h-[100%] flex-none flex-col gap-xs rounded-2xs border border-solid border-border bg-white p-s shadow-md">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2xs">
          <div
            className="size-[14px] rounded-[2px]"
            style={{ backgroundColor: color }}
          />
          <Text variant="description" className="max-w-[160px] truncate">
            {tag.displayName ?? tag.tagName}
          </Text>
        </div>
        <div className="flex items-center gap-2xs">
          <Text variant="description" className="text-text-secondary">
            Recommended
          </Text>
          <Text bold>
            <span>
              {recommendedValue
                ? Math.round(recommendedValue * 100) / 100
                : 'N/A'}
            </span>
          </Text>
        </div>
      </div>
      <div className="flex-1 overflow-hidden">
        <TimeSeriesChart
          tooltip
          tooltipPerData
          data={[
            { data: tagDataQuery.data, hideArea: true, color: color },
            {
              data: historicalPrediction.data,
              hideArea: true,
              color: theme.text.warning.DEFAULT,
            },
          ]}
          isPending={tagDataQuery.isLoading || historicalPrediction.isLoading}
        />
      </div>
    </div>
  )
}
