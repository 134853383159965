import { useNavigate, useParams } from 'react-router-dom'
import { add, sub } from 'date-fns'
import { useMemo } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { TimeRangeProvider } from 'src/contexts/timeRange'
import { CursorProvider } from 'src/contexts/cursor'
import { FilterProvider } from 'src/contexts/filter'
import { ModelAnomaliesProvider } from 'src/contexts/modelAnomalies'
import { useModel, useModelInputTags, useModelTypes } from 'models/api'
import { useSite } from 'src/contexts/site'
import { useTitle } from 'src/utility'
import { Text, Spinner, Card, Banner, Button } from 'src/components/ui'
import { metropolisDataRange } from 'src/utility/metropolis'
import { FetchModel, GqlModelTypeFragment, ModelInputTag } from 'src/services'
import { ErrorDisplay } from 'pages/app'
import {
  AnomalyDetectionModel,
  ForecastModel,
  PrescriptiveModel,
} from './variants'
import {
  AWAITING_ANOMALY_ANALYSIS,
  hasRunInitialInference,
} from './model.utils'

const offset = (model: FetchModel): number => {
  switch (model.method.name) {
    case 'Forecast 1H':
      return 60
    case 'Forecast 2H':
      return 120
    case 'Forecast 4H':
      return 240
    default:
      return 0
  }
}

const defaultToTime = (model: FetchModel): Date => {
  if (
    model.__typename === 'AnomalyModel' &&
    model.state === AWAITING_ANOMALY_ANALYSIS
  ) {
    const ts = model.initialInferenceEnd || model.activeTrainedModel?.dataEnd
    return ts ? ts : new Date()
  }

  if (
    model.__typename === 'ForecastModel' &&
    model.forecastHorizon &&
    hasRunInitialInference(model.state)
  ) {
    return add(new Date(), { seconds: model.forecastHorizon })
  }

  if (model.type.name === 'Forecast' && hasRunInitialInference(model.state)) {
    return add(new Date(), { minutes: offset(model) })
  }

  return new Date()
}

function defaultFromTime(model: FetchModel, defaultTo: Date): Date {
  if (model.state === AWAITING_ANOMALY_ANALYSIS) {
    if (model.initialInferenceStart) return model.initialInferenceStart
    return sub(defaultTo, { months: 6 })
  }

  if (model.__typename === 'ForecastModel' && model.forecastHorizon) {
    return sub(Date.now(), { days: 1 })
  }

  return sub(defaultTo, { days: 1 })
}

export function ModelPage(): JSX.Element {
  useTitle('Model')
  const { modelId } = useParams()
  if (!modelId) throw new Error('`modelId` route param missing')
  const modelQuery = useModel(modelId)
  const inputTagsQuery = useModelInputTags(modelId)
  const modelTypesQuery = useModelTypes()
  const navigate = useNavigate()

  if (
    modelQuery.isLoading ||
    modelTypesQuery.isLoading ||
    inputTagsQuery.isLoading
  ) {
    return (
      <div className="m-[1em] mt-0">
        <Card>
          <Text variant="title" bold>
            Model
          </Text>
          <Spinner />
        </Card>
      </div>
    )
  }

  if (modelQuery.isError || modelTypesQuery.isError || inputTagsQuery.isError) {
    const errorQuery = [modelQuery, modelTypesQuery, inputTagsQuery].find(
      query => query.isError,
    )

    if (!errorQuery) throw new Error('No error query found')
    return (
      <ErrorDisplay
        error={errorQuery.error}
        message="Failed to load model"
        action={errorQuery.refetch}
      />
    )
  }

  if (!modelQuery.data || !modelTypesQuery.data || !inputTagsQuery.data) {
    return (
      <ErrorDisplay
        message="Model not found"
        error={new Error('Model not found')}
        action={() => navigate('..')}
      />
    )
  }

  return (
    <ModelPageContent
      model={modelQuery.data}
      modelTypes={modelTypesQuery.data}
      inputTags={inputTagsQuery.data}
    />
  )
}

type ModelPageContent = {
  model: FetchModel
  modelTypes: GqlModelTypeFragment[]
  inputTags: ModelInputTag[]
}

function ModelPageContent({
  model,
  modelTypes,
  inputTags,
}: ModelPageContent): JSX.Element {
  const { isWorkshop } = useSite()
  const { prescriptiveModels } = useFlags()
  const navigate = useNavigate()

  const modelContent = useMemo((): JSX.Element => {
    switch (model.__typename) {
      case 'ForecastModel':
        return (
          <FilterProvider>
            <ForecastModel model={model} modelTypes={modelTypes} />
          </FilterProvider>
        )
      case 'AnomalyModel':
        return (
          <FilterProvider condition={model.anomalyGenerationFilter}>
            <ModelAnomaliesProvider modelId={model.id}>
              <AnomalyDetectionModel model={model} modelTypes={modelTypes} />
            </ModelAnomaliesProvider>
          </FilterProvider>
        )
      case 'PrescriptiveModel': {
        if (prescriptiveModels) {
          return (
            <FilterProvider>
              <PrescriptiveModel
                model={model}
                modelTypes={modelTypes}
                inputTags={inputTags}
              />
            </FilterProvider>
          )
        } else {
          return (
            <Banner
              variant="error"
              rightComponent={
                <Button
                  variant="primary"
                  title="Back to model list"
                  onClick={() => navigate('..')}
                />
              }
            >
              We currently don't support this model type
            </Banner>
          )
        }
      }
      default:
        throw new Error('Unknown model type')
    }
  }, [inputTags, model, modelTypes, navigate, prescriptiveModels])

  // we now have a valid model. Work out the time default range
  const defaultTo = defaultToTime(model)
  const defaultTimeRange = isWorkshop
    ? metropolisDataRange
    : {
        from: defaultFromTime(model, defaultTo).valueOf(),
        to: defaultTo.valueOf(),
      }

  return (
    <div className="m-[1em] mt-0">
      <TimeRangeProvider urlQuery defaultTimeRange={defaultTimeRange}>
        <CursorProvider>{modelContent}</CursorProvider>
      </TimeRangeProvider>
    </div>
  )
}
