import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import { TimeSeriesData } from 'src/types'
import * as api from 'src/services'
import { processTimeSeries } from 'src/utility/timeSeries'
import useTimeRange from 'src/contexts/timeRange'
import { timeSeriesRefetch } from 'trend/api'
import { useIsTabVisible } from 'src/utility'

export function modelPredictionQuery(
  req: api.FetchModelPrediction,
  enabled = true,
): UseQueryOptions<TimeSeriesData[]> {
  return {
    queryKey: ['modelPrediction', req],
    queryFn: async () => {
      const data = await api.fetchModelPrediction(req)
      return processTimeSeries(data)
    },
    enabled,
    refetchInterval: () => timeSeriesRefetch(req.timeRange),
  }
}

interface UseModelPrediction {
  id: string
}

export function useModelPrediction({
  id,
}: UseModelPrediction): UseQueryResult<TimeSeriesData[]> {
  const { timeRange } = useTimeRange()
  const isTabVisible = useIsTabVisible()
  const query = modelPredictionQuery({ id, timeRange }, isTabVisible)
  return useQuery(query)
}

interface useModelHistoricalPrediction {
  modelId: string
  tagId: string
}

export function useModelHistoricalPrediction({
  modelId,
  tagId,
}: useModelHistoricalPrediction): UseQueryResult<TimeSeriesData[]> {
  const { timeRange } = useTimeRange()

  return useQuery({
    queryKey: ['modelHistoricalPrediction', modelId, tagId, timeRange],
    queryFn: async () => {
      const data = await api.getProcessValuesForPrescriptive(
        modelId,
        tagId,
        timeRange.from,
        timeRange.to,
      )
      return processTimeSeries(data)
    },
  })
}
