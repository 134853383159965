import classNames from 'classnames'
import { RUNNING } from 'pages/site/models/model/model.utils'
import { Banner, Button, Card, Spinner, Text } from 'src/components/ui'
import {
  FetchPrescriptiveModel,
  GqlLatestRecomendationFragment,
  ModelInputTag,
} from 'src/services'
import { useTimeSeriesData } from 'trend/api'
import { useLatestValueTimeRange } from '../../prescriptive-model.utils'
import { RecomendationTable } from './components'

type Props = {
  model: FetchPrescriptiveModel
  controllableInputTags: ModelInputTag[]
  latestRecomendations?: GqlLatestRecomendationFragment
}

export function RecomendedImprovements({
  model,
  controllableInputTags,
  latestRecomendations,
}: Props): JSX.Element {
  const timeRange = useLatestValueTimeRange()
  const latestValues = useTimeSeriesData(
    controllableInputTags.map(t => ({ id: t.node.tagName, type: 'tag' })),
    timeRange,
  )

  function getContentComponent(): JSX.Element {
    if (latestValues.some(v => v.isLoading)) {
      return <Spinner />
    }
    if (!latestRecomendations || latestValues.some(v => v.isError)) {
      const errorQuery = latestValues.find(v => v.isError)
      return (
        <Banner
          variant="error"
          rightComponent={
            <Button
              variant="primary"
              title="Try again"
              onClick={() => errorQuery?.refetch()}
            />
          }
        >
          Failed to fetch latest recomendation
        </Banner>
      )
    }

    const currentValues = controllableInputTags.map((tag, index) => {
      const { data } = latestValues[index]
      return {
        id: tag.node.id,
        value: data ? data[data.length - 1][1] : undefined,
      }
    })

    return (
      <RecomendationTable
        controllableInputTags={controllableInputTags}
        latestRecomendations={latestRecomendations}
        currentValues={currentValues}
      />
    )
  }

  return (
    <div className="col-span-2 flex flex-col gap-s">
      <div className="flex flex-col gap-2xs">
        <Text variant="title" bold>
          Recommended improvements (
          <span>
            {latestRecomendations?.latest?.recommendations.length ?? 0}
          </span>
          )
        </Text>
        <Text>
          Controllable inputs will contribute to a positive change towards the
          output target.
        </Text>
      </div>
      <Card
        className={classNames(
          model.state !== RUNNING &&
            'flex min-h-[104px] items-center justify-center',
        )}
      >
        {model.state !== RUNNING ? (
          <Text className="text-text-secondary">
            You do not have recommendations.{' '}
            <span className="text-text-brand">Activate</span> model to get
            recommendations.
          </Text>
        ) : (
          getContentComponent()
        )}
      </Card>
    </div>
  )
}
