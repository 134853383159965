import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Button, Icon, Modal, Text } from 'src/components/ui'
import { Asset } from 'src/types'

interface DeleteAssetModalProps {
  onClickCross: () => void
  onClickConfirm: (asset: Asset) => void
  onClickCancel: () => void
  asset: Asset
  hasChildren?: boolean
  hasTags?: boolean
  isPending?: boolean
}

export function DeleteAssetModal({
  onClickCross,
  onClickConfirm,
  onClickCancel,
  asset,
  hasChildren = false,
  hasTags = false,
  isPending,
}: DeleteAssetModalProps): JSX.Element {
  const { alias, name } = asset

  return (
    <Modal title="Delete Asset" isOpen close={onClickCross}>
      {hasChildren || hasTags ? (
        <div className="flex size-full items-center justify-center p-5">
          <Icon
            icon={light('exclamation-circle')}
            className="mr-3 text-[2em]"
          />
          <div className="flex flex-col gap-3">
            {hasChildren && (
              <Text className="text-lg">
                <b>Child assets</b> must be moved to a new parent before
                deleting
              </Text>
            )}
            {hasTags && (
              <Text className="text-lg">
                <b>Tags</b> must be moved to a new asset before deleting
              </Text>
            )}
          </div>
        </div>
      ) : (
        <div className="mt-3 flex flex-col justify-center gap-4 px-4">
          <Text className="text-lg">
            Are you sure you want to delete<b> {alias || name}</b> ?{' '}
          </Text>
          <div className="flex justify-center pt-[10px]">
            <Button
              variant="primary"
              title="Confirm"
              disabled={isPending}
              onClick={() => onClickConfirm(asset)}
              className="mr-[10px] mt-[10px]"
            />
            <Button
              variant="secondary"
              title="Cancel"
              onClick={onClickCancel}
              className="mr-[10px] mt-[10px]"
            />
          </div>
        </div>
      )}
    </Modal>
  )
}
