import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import classNames from 'classnames'
import { useState } from 'react'
import { Icon } from 'src/components/ui'
import { GqlLatestRecomendationFragment, ModelInputTag } from 'src/services'
import { GridLayout, RowLayout } from './components'

enum Layout {
  ROW = 'row',
  GRID = 'grid',
}

type Props = {
  modelId: string
  controllableInputTags: ModelInputTag[]
  latestRecomendations?: GqlLatestRecomendationFragment
}

export function TagCharts({
  modelId,
  controllableInputTags,
  latestRecomendations,
}: Props): JSX.Element {
  const [layout, setLayout] = useState<Layout>(Layout.ROW)

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-end gap-xs">
        <LayoutButton
          isActive={layout === Layout.GRID}
          icon={light('grid-2')}
          onClick={() => setLayout(Layout.GRID)}
        />
        <LayoutButton
          isActive={layout === Layout.ROW}
          icon={light('list-ul')}
          onClick={() => setLayout(Layout.ROW)}
        />
      </div>
      <>
        {layout === Layout.ROW && (
          <RowLayout
            modelId={modelId}
            inputTags={controllableInputTags}
            latestRecomendations={latestRecomendations}
          />
        )}
        {layout === Layout.GRID && (
          <GridLayout
            modelId={modelId}
            inputTags={controllableInputTags}
            latestRecomendations={latestRecomendations}
          />
        )}
      </>
    </div>
  )
}

type LayoutButtonProps = {
  isActive: boolean
  icon: IconProp
  onClick: () => void
}

function LayoutButton({
  isActive,
  icon,
  onClick,
}: LayoutButtonProps): JSX.Element {
  return (
    <div
      onClick={onClick}
      className={classNames(
        'size-[30px] flex items-center justify-center rounded-2xs border border-solid cursor-pointer transition duration-300',
        isActive
          ? 'bg-background-brand-hover text-text-brand border-border-brand'
          : 'border-border-disabled text-text-tertiary',
      )}
    >
      <Icon icon={icon} />
    </div>
  )
}
