import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useNavigate } from 'react-router-dom'
import { Button, Icon, Text } from 'src/components/ui'
import { WorkspaceState } from 'src/dex/workspace'

type Props = {
  workspace: WorkspaceState
}

export function WorkspaceCard({ workspace }: Props): JSX.Element {
  const navigate = useNavigate()
  return (
    <>
      <div
        className="flex w-[570px] rounded-2xs border border-solid border-border p-m pr-s"
        key={workspace.id}
      >
        <Icon
          icon={light('folder-magnifying-glass')}
          className="mr-s !h-[30px] !w-[36px] text-icon-secondary"
        />
        <div style={{ display: 'flex', width: '100%' }}>
          <div className="flex w-full items-start justify-between">
            <div>
              <Text variant="description">Workspace</Text>
              <Text variant="content" bold className="mb-xs mt-2xs">
                {workspace.label}
              </Text>
              <Text variant="small" className="mt-s">
                Description
              </Text>
              <Text
                variant="description"
                bold
                className="mt-2xs line-clamp-3 min-h-xl"
              >
                {workspace.description}
              </Text>
            </div>
            <div>
              <Button
                variant={'primary'}
                title="View Details"
                className="mb-xs"
                onClick={() => navigate(`${workspace.id}`)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
