import { useModal } from 'react-modal-hook'
import { Button } from 'src/components/ui'
import { ConfirmModal } from '../ConfirmModal'

type Props = {
  isLoading: boolean
  onConfirm: () => Promise<void>
  nodesAdded: number
  nodesRemoved: number
  isError: boolean
}

export function SaveSubscriptionButton({
  isLoading,
  onConfirm,
  nodesAdded,
  nodesRemoved,
  isError,
}: Props): JSX.Element {
  // Confirm changes modal
  const [showEditSubscriptionModal, closeEditSubscriptionModal] =
    useModal(() => {
      async function handleConfirm(): Promise<void> {
        await onConfirm()
        if (!isError) closeEditSubscriptionModal()
      }
      return (
        <ConfirmModal
          isError={isError}
          isLoading={isLoading}
          onClose={closeEditSubscriptionModal}
          onConfirm={handleConfirm}
          tagsAdded={nodesAdded}
          tagsRemoved={nodesRemoved}
        />
      )
    }, [isError, nodesAdded, nodesRemoved, isLoading, onConfirm])

  return (
    <Button
      disabled={nodesAdded === 0 && nodesRemoved === 0}
      variant="primary"
      title="Save Changes"
      onClick={showEditSubscriptionModal}
    />
  )
}
