import classNames from 'classnames'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Text } from 'src/components/ui'
import { ModelTypes } from 'src/types'

interface ModelTypeStepProps {
  modelType?: ModelTypes | null
  setModelType: (type: ModelTypes) => void
}

export function ModelTypeStep({
  modelType,
  setModelType,
}: ModelTypeStepProps): JSX.Element {
  const { prescriptiveModels } = useFlags()
  return (
    <>
      <Text variant="title" bold className="my-m text-center !text-3xl">
        What is the goal of this model?
      </Text>
      <div className="flex justify-center gap-12">
        {prescriptiveModels && (
          <button
            className={classNames(
              'text-left bg-background border-[2px] border-solid rounded-[8px] p-[1em] cursor-pointer max-w-[250px]',
              modelType === 'PrescriptiveModel'
                ? 'border-border-success-secondary'
                : 'border-border',
            )}
            onClick={() => setModelType('PrescriptiveModel')}
          >
            <Text variant="title">To optimize a process</Text>
            <Text className="my-s">
              A <span className="font-500">prescriptive model</span> guides you
              to the best actions by calculating optimal solutions for your
              goals.
            </Text>
          </button>
        )}
        <button
          className={classNames(
            'text-left bg-background border-[2px] border-solid rounded-[8px] p-[1em] cursor-pointer max-w-[250px]',
            modelType === 'ForecastModel'
              ? 'border-border-success-secondary'
              : 'border-border',
          )}
          onClick={() => setModelType('ForecastModel')}
        >
          <Text variant="title">To forecast a value</Text>
          <Text className="my-s">
            A <span className="font-500">forecast model</span> lets you look
            into the future by predicting the value of a tag.
          </Text>
        </button>
        <button
          className={classNames(
            'text-left bg-background border-[2px] border-solid rounded-[8px] p-[1em] cursor-pointer max-w-[250px]',
            modelType === 'AnomalyModel'
              ? 'border-border-success-secondary'
              : 'border-border',
          )}
          onClick={() => setModelType('AnomalyModel')}
        >
          <Text variant="title">To identify anomalies</Text>
          <Text className="my-s">
            An <span className="font-500">anomaly model</span> identifies when
            the process deviates from expected behavior.
          </Text>
        </button>
      </div>
    </>
  )
}
