import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'
import { datadogRum } from '@datadog/browser-rum'
import { useSite } from 'src/contexts/site'
import * as api from 'src/services'
import { MODELS_QUERY_KEY } from 'models/api'

type Variables = Omit<api.CreateModel, 'factory'>

export function useCreateModelMutation(): UseMutationResult<
  api.GqlModelBaseFragment,
  unknown,
  Variables
> {
  const queryClient = useQueryClient()
  const { id: factory } = useSite()

  return useMutation({
    mutationFn: async (input: Variables) => {
      const model = await api.createModel({ ...input, factory })
      if (!model) {
        throw new Error('Model creation failed')
      }
      return model
    },
    onSuccess: data => {
      datadogRum.addAction('Model created', {
        id: data.id,
        type: data.type.name,
        method: data.method.name,
        inputTags: data.inputTags.map(t => t.tagName),
        outputTag: data.tag.tagName,
      })
      queryClient.invalidateQueries([MODELS_QUERY_KEY, factory])
    },
  })
}

type PrescriptiveModelVariables = Omit<
  api.GqlCreatePrescriptiveModelInput,
  'factoryId'
>

export function useCreatePrescriptiveModelMutation(): UseMutationResult<
  api.GqlModelBaseFragment,
  unknown,
  PrescriptiveModelVariables
> {
  const queryClient = useQueryClient()
  const { id: factory } = useSite()

  return useMutation({
    mutationFn: async (input: PrescriptiveModelVariables) => {
      const model = await api.createPrescriptiveModel({
        ...input,
        factoryId: factory,
      })
      if (!model) {
        throw new Error('Model creation failed')
      }
      return model
    },
    onSuccess: data => {
      datadogRum.addAction('Prescriptive model created', {
        id: data.id,
        type: data.type.name,
        method: data.method.name,
        inputTags: data.inputTags.map(t => t.tagName),
        outputTag: data.tag.tagName,
      })
      queryClient.invalidateQueries([MODELS_QUERY_KEY, factory])
    },
  })
}

export function useCopyModelMutation(): UseMutationResult<
  api.GqlModelBaseFragment | null | undefined,
  unknown,
  api.GqlCopyModelInput
> {
  const queryClient = useQueryClient()
  const { id: factoryId } = useSite()
  return useMutation({
    mutationFn: async ({ name, sourceId }: api.GqlCopyModelInput) => {
      return api.copyModel({ name, sourceId })
    },
    onSuccess: () => {
      queryClient.invalidateQueries([MODELS_QUERY_KEY, factoryId])
    },
  })
}
