import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { getOutputTagWarnings, getInputTagWarnings } from 'pages/site/models'
import { Button, Icon, Tooltip } from 'src/components/ui'
import { useSite } from 'src/contexts/site'
import { GqlTagClassification, TagDto } from 'src/services'
import { ModelTypes } from 'src/types'
import { zIndex } from 'src/utility/constants/StyleConstants'

interface MessagesProps {
  error?: boolean
  messages: React.ReactNode[]
  isModal?: boolean
}

const Messages = ({ messages, error, isModal }: MessagesProps): JSX.Element => (
  <Tooltip
    zIndex={isModal ? zIndex.modal + 1 : undefined}
    render={() => messages.map((message, i) => <div key={i}>{message}</div>)}
  >
    <div className="flex flex-col justify-center">
      {error ? (
        <Icon
          icon={light('circle-exclamation')}
          className="text-[2em] text-text-danger"
        />
      ) : (
        <Icon
          icon={light('triangle-exclamation')}
          className="text-[2em] text-icon-warning"
        />
      )}
    </div>
  </Tooltip>
)

interface TagActionProps {
  type: 'Add' | 'Remove' | 'Set' | 'Display' | 'Update'
  tag: TagDto
  modelType?: ModelTypes | null
  modelOutputTagName?: string | null
  inputTags?: string[]
  tagAction: (tag: string) => void
  isPending?: boolean
  isModal?: boolean
}

export function TagAction({
  type,
  tag,
  modelType,
  modelOutputTagName,
  inputTags,
  tagAction,
  isPending,
  isModal,
}: TagActionProps): JSX.Element {
  const { viewerRole, rootLink } = useSite()
  let hasError = false
  const label = tag.overriddenLabel ?? tag.automaticLabel
  if (type === 'Add') {
    hasError = !label
  }
  if (type === 'Set' || type === 'Update') {
    hasError = !label || label === GqlTagClassification.Categorical
  }

  const messages =
    type === 'Set' || type === 'Update'
      ? getOutputTagWarnings(viewerRole, rootLink, tag)
      : getInputTagWarnings(
          viewerRole,
          rootLink,
          tag,
          modelType,
          modelOutputTagName,
        )

  if (hasError) {
    return (
      <div className="grid grid-flow-col items-center gap-4">
        <div />
        <Messages messages={messages} error isModal={isModal} />
      </div>
    )
  }

  // all messages to be treated as errors have been handled
  // if there are any messages now, they are warnings
  const hasWarning = messages.length > 0
  const variant = hasWarning ? 'secondary' : 'primary'

  let action: React.ReactNode
  switch (type) {
    case 'Add': {
      action = (
        <Button
          title="Add"
          variant={variant}
          onClick={() => tagAction(tag.tagName)}
        />
      )
      break
    }
    case 'Remove': {
      action = (
        <Button
          title="Remove"
          buttonColor="danger"
          variant="secondary"
          onClick={() => tagAction(tag.tagName)}
        />
      )
      break
    }
    case 'Display': {
      action = (
        <Button
          title="Remove"
          variant="secondary"
          buttonColor="danger"
          onClick={() => tagAction(tag.tagName)}
        />
      )
      break
    }
    case 'Set': {
      action = (
        <Button
          title="Select"
          variant={variant}
          onClick={() => tagAction(tag.tagName)}
        />
      )
      break
    }
    case 'Update': {
      action = (
        <Button
          title={isPending ? '' : 'Select'}
          variant={variant}
          isPending={isPending}
          onClick={() => tagAction(tag.tagName)}
        />
      )
      break
    }
    default: {
      const x: never = type
      throw new Error(`Unexpected action type: ${x}`)
    }
  }

  return (
    <div className="grid grid-flow-col items-center gap-4">
      {action}
      {hasWarning && <Messages messages={messages} isModal={isModal} />}
    </div>
  )
}
