import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import { minutesToSeconds } from 'date-fns'
import {
  CreateModel,
  GqlModelMethodFragment,
  GqlModelTypeFragment,
  TagDto,
} from 'src/services'
import { isDefined } from 'src/types'
import {
  useCreateModelMutation,
  useCreatePrescriptiveModelMutation,
} from '../api'
import { Record } from './steps'

type CreateModelProps = {
  model: Record
  modelType: GqlModelTypeFragment
  method: GqlModelMethodFragment
  tags: TagDto[]
  tag: TagDto
}

type UseModelCreation = {
  createModel: (props: CreateModelProps) => Promise<void>
  isLoading: boolean
}

export function useModelCreation(): UseModelCreation {
  const navigate = useNavigate()
  const createModelMutation = useCreateModelMutation()
  const createPrescriptiveModelMutation = useCreatePrescriptiveModelMutation()

  const handleSuccess = (data: any): void => {
    navigate(`../${data.id}`, { state: { edit: true } })
  }

  const handleError = (): void => {
    toast.error('Failed to create model', { position: 'top-right' })
  }

  async function createPrescriptiveModel({
    model,
    tags,
    tag,
  }: CreateModelProps): Promise<void> {
    if (model.targetValue === undefined) {
      console.error('Target value is required for prescriptive model')
      return
    }

    await createPrescriptiveModelMutation.mutateAsync(
      {
        targetTagId: tag.tagNodeId,
        name: model.name ?? '',
        inputTags:
          model.inputTags
            ?.map(tagName => {
              const t = tags.find(tag => tag.tagName === tagName)
              return t
                ? {
                    tagId: t.tagNodeId,
                    isControllable: !!model.controllableTags?.includes(tagName),
                  }
                : undefined
            })
            .filter(isDefined) ?? [],
        targetValue: model.targetValue,
      },
      {
        onSuccess: handleSuccess,
        onError: handleError,
      },
    )
  }

  async function createStandardModel({
    model,
    method,
    modelType,
    tags,
    tag,
  }: CreateModelProps): Promise<void> {
    const modelToCreate: Omit<CreateModel, 'factory'> = {
      name: model.name ?? '',
      typeId: modelType.id,
      methodId: method.id,
      description: model.description ?? '',
      tagId: tag.tagNodeId,
      forecastHorizon:
        model.modelType === 'ForecastModel' ? minutesToSeconds(60) : undefined,
      inputTagIds:
        (model.inputTags &&
          model.inputTags
            .map(
              tagName => tags.find(tag => tag.tagName === tagName)?.tagNodeId,
            )
            .filter(isDefined)) ??
        [],
      trainingPeriod: 'P1Y',
    }

    await createModelMutation.mutateAsync(modelToCreate, {
      onSuccess: handleSuccess,
      onError: handleError,
    })
  }

  const createModel = async (props: CreateModelProps): Promise<void> => {
    if (props.modelType.name === 'Prescriptive') {
      createPrescriptiveModel(props)
    } else {
      createStandardModel(props)
    }
  }

  return {
    createModel,
    isLoading:
      createModelMutation.isLoading ||
      createPrescriptiveModelMutation.isLoading,
  }
}
