import { Record } from 'models/create'

export function getTitle(model: Record): string {
  switch (model.modelType) {
    case 'AnomalyModel':
      return 'Which tag do you want to identify anomalies for?'
    case 'ForecastModel':
      return 'Which tag do you want to predict?'
    case 'PrescriptiveModel':
      return 'Which tag do you want to optimize?'
    default:
      throw new Error(`Unknown model type: ${model.modelType}`)
  }
}
