import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { GqlOpcUaNode } from 'src/services'
import { Icon, Text, Tooltip } from 'src/components/ui'
import { zIndex } from 'src/utility/constants'
import { humanDateTime } from 'src/utility/time'
import { getNodeIcon } from '../../../opc-ua-hierarchy.utils'

type Props = {
  params: ICellRendererParams
  errorNodes: string[]
}

export function GroupColumn({ params, errorNodes }: Props): JSX.Element {
  const nodeData = params.data as GqlOpcUaNode & { path: string[] }
  const { icon, className } = getNodeIcon(
    nodeData.class,
    nodeData.typeDefinition ?? undefined,
  )
  const isErrorNode = errorNodes.includes(nodeData.id)
  const isChildOfErrorNode = nodeData.path
    .slice(0, nodeData.path.length - 1)
    .some((id: string) => errorNodes.includes(id))
  return (
    <div className="relative flex items-center gap-xs">
      {isErrorNode && (
        <Tooltip
          zIndex={zIndex.modalLegendMenu}
          direction="bottom"
          render={() => {
            const errorMessage = nodeData.lastBrowseError ?? 'Unknown error'
            const timestamp = nodeData.lastBrowsedAt
              ? humanDateTime(nodeData.lastBrowsedAt)
              : undefined
            return (
              <Text>
                {timestamp ? `${timestamp}: ${errorMessage}` : errorMessage}
              </Text>
            )
          }}
        >
          <Icon
            icon={light('circle-exclamation')}
            className="text-icon-danger"
            size="regular"
          />
        </Tooltip>
      )}
      <Tooltip
        isOpen={isChildOfErrorNode ? undefined : false}
        zIndex={zIndex.modalLegendMenu}
        direction="bottom"
        render={() => (
          <Text>
            Node was last seen {humanDateTime(params.data.lastSeenAt)}
          </Text>
        )}
      >
        <div className="flex items-center gap-xs">
          {!isErrorNode && (
            <Icon icon={icon} size="regular" className={className} />
          )}
          <Tooltip
            direction="right"
            isOpen={isChildOfErrorNode ? false : undefined}
            zIndex={zIndex.modalLegendMenu}
            render={() => nodeData.nodeId}
          >
            {nodeData.displayName ?? nodeData.browseName}
          </Tooltip>
        </div>
      </Tooltip>
    </div>
  )
}
