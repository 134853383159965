import classNames from 'classnames'
import { Text } from 'src/components/ui'
import { GqlLatestRecomendationFragment, ModelInputTag } from 'src/services'
import { getGraphColor } from 'src/utility/graphUtils/graphColors'

type Props = {
  controllableInputTags: ModelInputTag[]
  latestRecomendations: GqlLatestRecomendationFragment
  currentValues: { id: string; value: number | undefined }[]
}
export function RecomendationTable({
  controllableInputTags,
  latestRecomendations,
  currentValues,
}: Props): JSX.Element {
  return (
    <div className="grid grid-cols-[1fr,auto,auto]">
      <Text bold>Controllable Tags</Text>
      <Text bold className="mx-m">
        Current Value
      </Text>
      <Text bold>Recommended Value</Text>
      {controllableInputTags.map((tag, index) => {
        const color = getGraphColor(tag.node.tagName)
        const recomendation = latestRecomendations.latest?.recommendations.find(
          t => t.tagId === tag.node.id,
        )
        const currentValue = currentValues.find(v => v.id === tag.node.id)
        return (
          <>
            <div
              className={classNames(
                'flex items-center gap-xs py-[12px] pr-m',
                index !== controllableInputTags.length - 1 &&
                  'border-0 border-b border-solid border-border',
                index === 0 && 'pt-s',
              )}
            >
              <div
                className="size-s rounded-[2px]"
                style={{ backgroundColor: color }}
              />
              <Text className="text-right text-text-secondary">
                {tag.node.displayName ?? tag.node.tagName}
              </Text>
            </div>
            <Text
              className={classNames(
                'text-text-secondary text-right py-[12px] px-m',
                index !== controllableInputTags.length - 1 &&
                  'border-0 border-b border-solid border-border',
                index === 0 && 'pt-s',
              )}
            >
              {currentValue?.value
                ? Math.round(currentValue.value * 100) / 100
                : 'N/A'}
            </Text>
            <Text
              className={classNames(
                'text-text-secondary text-right py-[12px] pl-m',
                index !== controllableInputTags.length - 1 &&
                  'border-0 border-b border-solid border-border',
                index === 0 && 'pt-s',
              )}
            >
              {recomendation?.value
                ? Math.round(recomendation.value * 100) / 100
                : 'N/A'}
            </Text>
          </>
        )
      })}
    </div>
  )
}
