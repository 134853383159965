import { useTagData } from 'pages/site/tags/api'
import { TimeSeriesChart } from 'src/components/ui'
import { GqlModelInputTagDetailsFragment } from 'src/services'
import { getGraphColor } from 'src/utility/graphUtils/graphColors'

type Props = {
  tag: GqlModelInputTagDetailsFragment
}

export function Chart({ tag }: Props): JSX.Element {
  const tagDataQuery = useTagData({ id: tag.tagName })
  const color = getGraphColor(tag.tagName)

  return (
    <div className="flex-1">
      <TimeSeriesChart
        tooltip
        data={[{ data: tagDataQuery.data, hideArea: true, color: color }]}
        isPending={tagDataQuery.isLoading}
      />
    </div>
  )
}
