import { useEffect, useState } from 'react'

type UseLatestValueTimeRange = {
  from: number
  to: number
}

export function useLatestValueTimeRange(): UseLatestValueTimeRange {
  const [timeRange, setTimeRange] = useState({
    from: Date.now() - 5 * 60 * 1000,
    to: Date.now(),
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRange({
        from: Date.now() - 5 * 60 * 1000,
        to: Date.now(),
      })
    }, 60_000)

    return () => clearInterval(interval)
  }, [])

  return timeRange
}
