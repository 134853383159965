import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import classNames from 'classnames'
import { Icon, Text, Switch } from 'src/components/ui'

type ControllableInputTagsSelectorProps = {
  inputTags?: string[]
  controllableTags?: string[]
  setControllableTag: (tag: string, controllable: boolean) => void
  removeInputTag: (tag: string) => void
}

export function ControllableInputTagsSelector({
  inputTags,
  controllableTags,
  setControllableTag,
  removeInputTag,
}: ControllableInputTagsSelectorProps): JSX.Element {
  return (
    <div className="mb-m rounded-xs border border-solid border-border bg-background">
      <div className="mr-[40px] flex items-center justify-between px-s pt-s">
        <Text bold>Input Tags</Text>
        <div className="flex items-center gap-xs">
          <Text bold>Controllable</Text>
          <Icon icon={light('circle-info')} />
        </div>
      </div>
      <div className="mt-xs max-h-[580px] overflow-y-auto">
        {inputTags?.map((tag, index) => {
          return (
            <div
              key={tag}
              className={classNames(
                'flex items-center justify-between hover:bg-background-hover transition',
                index < inputTags.length - 1
                  ? 'p-s border-0 border-b border-solid border-border'
                  : 'p-s',
              )}
            >
              <Text>{tag}</Text>
              <div className="flex items-center gap-m">
                <Switch
                  value={controllableTags?.includes(tag) ?? false}
                  onChange={value => setControllableTag(tag, value)}
                />
                <Icon
                  onClick={() => removeInputTag(tag)}
                  icon={light('xmark')}
                  className="w-s cursor-pointer"
                />
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
