import React from 'react'
import { SiteRole } from 'src/types'

export interface SiteContextValue {
  id: string
  name: string
  viewerRole: SiteRole
  isWorkshop: boolean
  isDataExplorerWorkshop: boolean
  rootLink: string
}

export const SiteContext = React.createContext<SiteContextValue | undefined>(
  undefined,
)
