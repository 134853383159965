import { differenceInSeconds, formatISO } from 'date-fns'
import { graphqlApi } from './graphQL'
import { GqlTsDataFragment } from './graphqlTypes'

// the default number of data points we want back
const minNumberOfDataPoints = 400

export async function getProcessValues(
  tagName: string,
  from: Date | number,
  to: Date | number,
  factory: string,
  interval?: number,
  signal?: AbortSignal,
): Promise<GqlTsDataFragment[]> {
  if (!interval) {
    const seconds = differenceInSeconds(to, from)
    interval = Math.floor(seconds / minNumberOfDataPoints)
  }

  const api = await graphqlApi(signal)
  const data = await api.ProcessData({
    factory,
    tagName,
    from: formatISO(from),
    to: formatISO(to),
    interval,
  })

  // FIXME if data is undefined then it is probably an error state
  return data.factory?.tagByName?.processData?.data ?? []
}

export async function getProcessValuesForPrescriptive(
  modelId: string,
  tagId: string,
  from: number,
  to: number,
  signal?: AbortSignal,
): Promise<GqlTsDataFragment[]> {
  const seconds = differenceInSeconds(to, from)
  const interval = Math.floor(seconds / minNumberOfDataPoints)

  const api = await graphqlApi(signal)
  const data = await api.FetchHistoricalRecommendations({
    modelId,
    tagId,
    from: formatISO(from),
    to: formatISO(to),
    interval,
  })

  if (data.node?.__typename !== 'PrescriptiveModel') {
    return []
  }

  return data.node.historicalRecommendations.data ?? []
}
