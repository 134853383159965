import { memo } from 'react'
import { Button } from 'src/components/ui'
import { Record, steps } from 'models/create'

type CreateModelNavigationButtonsProps = {
  model: Record
  isLoading: boolean
  step: number
  setStep: React.Dispatch<React.SetStateAction<number>>
  handleNextStep: (nextStep: number) => void
  handleCreateModel: () => void
  setIsAddInputTagsModalOpen: (isOpen: boolean) => void
}

export const CreateModelNavigationButtons = memo(
  function CreateModelNavigationButtons({
    model,
    isLoading,
    step,
    setStep,
    handleNextStep,
    handleCreateModel,
    setIsAddInputTagsModalOpen,
  }: CreateModelNavigationButtonsProps): JSX.Element {
    const showNext = step > 0 && step < steps.length - 1
    const showCreate = step === steps.length - 1
    const showChangeInput =
      step === 2 && model.modelType === 'PrescriptiveModel'

    return (
      <div className="flex justify-between">
        {step === 0 ? (
          <div />
        ) : (
          <Button
            variant="secondary"
            title="Back"
            onClick={() => setStep(step => step - 1)}
          />
        )}
        <div className="flex items-center gap-m">
          {showChangeInput && (
            <Button
              title="Change input tags"
              variant="secondary"
              onClick={() => setIsAddInputTagsModalOpen(true)}
            />
          )}
          {showNext && (
            <Button
              title="Next"
              variant={steps[step].completed(model) ? 'primary' : 'secondary'}
              disabled={!steps[step].completed(model)}
              onClick={() => handleNextStep(step + 1)}
            />
          )}
        </div>
        {showCreate && (
          <Button
            variant="primary"
            title="Create model"
            disabled={steps.some(step => !step.completed(model))}
            onClick={handleCreateModel}
            isPending={isLoading}
          />
        )}
      </div>
    )
  },
)
