import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Icon, Text, Modal } from 'src/components/ui'

type Props = {
  userName: string
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
}

export function DeactivateUserModal({
  userName,
  isOpen,
  onClose,
  onConfirm,
}: Props): JSX.Element {
  return (
    <Modal
      title="Deactivate User"
      isOpen={isOpen}
      close={onClose}
      footer={
        <Modal.Footer
          onCancel={onClose}
          onConfirm={onConfirm}
          confirmLabel="Deactivate User"
          type="danger"
          buttonDisabled={false}
        />
      }
    >
      <div className="flex max-w-[445px] flex-col items-center">
        <Icon
          icon={regular('circle-trash')}
          className="my-l size-xl text-text-danger"
        />
        <Text
          variant="content-rubik"
          bold
          className="my-xs text-center text-[16px]"
        >
          Are you sure you want to{' '}
          <span className="font-bold text-text-danger">deactivate</span>{' '}
          {userName}?
        </Text>
        <Text variant="content" className="my-s">
          Deactivating {userName}, will stop the user from being able to log in
          to Intelecy.
        </Text>
      </div>
    </Modal>
  )
}
